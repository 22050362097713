import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useRef } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import Aos from "aos";

const OurHistory = () => {
  const historyData = [
    {
      id: 1,
      year: 1996,
      desc: "Inception of My Healthskape",
    },
    {
      id: 2,
      year: 2000,
      desc: " Introduced WaveLight Excimer Lasers (Germany) in India for the first time, A revolution in LASIK technology.",
    },
    {
      id: 3,
      year: 2002,
      desc: "Introduced Moria Microkeratomes (France), an integral part of LASIK procedure.",
    },
    {
      id: 4,
      year: 2003,
      desc: "Partnered with Tomey (Japan) for a range of Ophthalmology diagnostic devices.",
    },
    {
      id: 5,
      year: 2004,
      desc: "Partnered with CSO (Italy) bringing world leading Slit Lamps and Topographers.",
    },
    {
      id: 6,
      year: 2015,
      desc: "Launched Optopol Revo OCT (Poland), from the pioneers of Spectral Domain OCT.",
    },
    {
      id: 7,
      year: 2019,
      desc: "Introduced SBM (Italy) and ESW (France), bringing a wave of Dry-Eyes diagnostic and treatment solutions.",
    },
    {
      id: 8,
      year: 2023,
      desc: " Bringing Capsulaser (USA) to India, a revolutionary new Laser procedure for Cataract Surgery.",
    }, 
  ];

  const historyRef = useRef(null);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  // Handler for scrolling left
  const scrollLeft = () => {
    if (historyRef.current) {
      historyRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  // Handler for scrolling right
  const scrollRight = () => {
    if (historyRef.current) {
      historyRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <div
      className="w-full md:px-[7.29vw] md:pt-[6.25vw]"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="flex items-start md:gap-x-55px-vw">
        <SectionHeading title={"OUR HISTORY"} customCss={"whitespace-nowrap"} />
        <ParaText
          paratext={
            "Established in 1996, My Healthskape Medicals Private Limited has been a leader in sourcing ophthalmic surgical equipment and diagnostic devices from various reputable international suppliers specializing in the ophthalmic industry."
          }
        />
      </div>
      <div className="w-full md:pt-86px-vw relative">
        <div className="border-[#393939]  border-[1px] border-opacity-30 border-solid"></div>
        <div
          className="border-[#2B2A29] top-[20%] left-[-2.7vw] absolute border border-solid rounded-full cursor-pointer p-16px-vw"
          onClick={scrollLeft}
        >
          <BsArrowLeft />
        </div>

        <div
          className="border-[#2B2A29] cursor-pointer top-[20%] right-[-2.7vw] absolute border border-solid rounded-full p-16px-vw"
          onClick={scrollRight}
        >
          <BsArrowRight />
        </div> 
       

        <div
          className="flex items-center w-full overflow-x-auto space-x-60px-vw scrollbar-hidden"
          ref={historyRef}
        >
          {historyData.map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center text-[#050709] font-poppins justify-center gap-y-16px-vw flex-shrink-0 w-[250px]" // Adjust the width as necessary
            >
              <div className="bg-[#519A7F] rounded-full absolute top-[27%]   w-16px-vw  z-10 h-16px-vw"></div>
              <div className="font-regular text-20px-vw opacity-80 mt-[1vw]">
                {item.year}
              </div>
              <div className="font-medium text-center text-20px-vw opacity-80">
                {item.desc}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurHistory;
