import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { dryice } from "../../../Data/CorneaRefractive";
import Marquee from "react-marquee-slider";
import BottomSlider from "../../slider/BottomSlider";

const Dryice = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeCategory = dryice[activeIndex];
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | Dry Eye"}
        centerHeading={"Dry Eye"}
      />

      <section
        className="w-full  md:py-96px-vw md:px-[7.29vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="flex items-center justify-center">
          <div className=" rounded-[4vw]  backdrop-blur-[30px] bg-white bg-opacity-60 md:flex md:flex-row flex-col items-start md:items-center justify-center space-x-60px-vw z-10  gap-y-40px-vh md:gap-y-0 p-24px-vw">
            {dryice.map((item, index) => (
              <div
                key={item.id}
                className={`relative cursor-pointer ${
                  activeIndex === index
                    ? "opacity-100 font-bold  md:rounded-[3vw] rounded-[8vw] p-30px-vw py-12px-vh"
                    : "opacity-40 font-medium"
                }`}
                onClick={() => setActiveIndex(index)}
              >
                {activeIndex === index && (
                  <div className="absolute green-shadow z-0 border "></div>
                )}
                {/* Category name */}
                <div
                  className={`relative ${
                    activeIndex === index
                      ? "glass-bg w-[250px] h-[50px]   "
                      : ""
                  } flex items-center justify-center `}
                >
                  <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.3vw] text-18px-mvw">
                    {item.model}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{item}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:max-w-[32.13vw] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full   gap-x-16px-vw md:pt-[3vw]  mx-auto flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[6.25vw]">
                  <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                    IMAGE & VIDEO GALLERY
                  </h1>

                  {/* Image Gallery Section */}
                  <div
                    className="w-full flex md:py-60px-vw items-center justify-center gap-x-[9vw]"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                  >
                    {item?.imggallery && item.imggallery.length >= 3 ? (
                      <Marquee
                        velocity={50}
                        minScale={0.8}
                        className="partner-marquee"
                        direction="left"
                        pauseOnHover={isPaused}
                      >
                        {item.imggallery.map((imgSrc, index) => (
                          <img
                            key={index}
                            src={imgSrc}
                            alt={`imgGallery-${index}`}
                            className="md:max-w-[18.23vw] md:mr-[6vw] object-cover"
                          />
                        ))}
                      </Marquee>
                    ) : (
                      // Render images directly if there are fewer than 3
                      <div className="flex gap-x-[9vw]">
                        {item?.imggallery?.map((imgSrc, index) => (
                          <img
                            key={index}
                            src={imgSrc}
                            alt={`imgGallery-${index}`}
                            className="md:max-w-[18.23vw] object-cover"
                          />
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Video and Image Navigation */}
                </div>
              ))}
            <div className="absolute left-0 md:w-full md:mb-[7vw]  border-b-2 opacity-10 border-[#2B2A29] z-10"></div>
            <div className="flex items-center gap-80px-vw relative">
              {item.video.map((videoItem, videoIndex) =>
                videoItem.videoData ? (
                  <BottomSlider key={videoIndex} slides={videoItem.videoData} />
                ) : (
                  <div
                    key={videoIndex}
                    className="mb-8 md:mt-60px-vw flex items-center justify-center gap-100px-vw w-full"
                  >
                    {videoItem.images &&
                      videoItem.images.map((imageSrc, imageIndex) => (
                        <div key={imageIndex} className="mb-8">
                          <img
                            src={imageSrc}
                            alt={`image-${imageIndex}`} // Unique alt tag for accessibility
                            className="md:max-w-[40.24vw] block"
                          />
                          <div className="md:mt-15px-vw text-[#2B2A29] font-poppins font-medium text-32px-vw">
                            Product Description
                          </div>
                        </div>
                      ))}
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </section>
    </main>
  );
};

export default Dryice;
