export const steamSterilizers = [
  {
    id: 1,
    model: "HS-60SL",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "HS-60SL",
        name: "Vertical type Steam Sterilizer (60 Liters)",
        main_points: [
          "Ergonomic design",
          "Automatic door system",
          " LCD monitor",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/sterilization/HS-60SL.png"],
        video: "",
      },
    ],
  },
  {
    id: 2,
    model: "HS-2522BL",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "HS-2522BL",
        name: "B-Class Steam Sterilizer (22 Liters)",
        main_points: [
          "Ergonomic design",
          "Graphic LCD monitor",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
          "Safe & durable unwelded chamber",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/sterilization/HS-2522BL.png"],
        video: "",
      },
    ],
  },
  {
    id: 3,
    model: "HS-2522SD",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "HS-2522SD",
        name: "S-Class Steam Sterilizer (22 Liters)",
        main_points: [
          "Ergonomic design",
          "LED display monitor",
          "Innovative air removal",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
          "Safe & durable unwelded chamber",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/sterilization/HS-2522SD.png"],
        video: "",
      },
    ],
  },
  {
    id: 4,
    model: "HS-1607SD",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "HS-1607SD",
        name: "S-Class Steam Sterilizer (7 Liters)",
        main_points: [
          "Ergonomic design",
          "LED display monitor",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
          "Safe & durable unwelded chamber",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/sterilization/HS-1607SD.png"],
        video: "",
      },
    ],
  },
];

export const indicators = [
  {
    id: 1,
    logo: "/images/sterilization/propper.png",
    model: "VAPOR LINE Steam Sterilization Indicator",
    name: "VAPOR LINE Steam Sterilization Integrators are reliable chemical indicators that monitor all critical steam sterilization parameters, making achieving safe and consistent sterilization results easy",
    main_points: [
      "Class 5 Indicator",
      "Equivalent to biological indicator",
      "Works on time, temperature & pressure",
      "Gives 100% guarantee of sterilization",
      "Available with or without extender",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Brochures",
      },
      {
        id: 2,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/sterilization/image002.png"],
    video: "",
  },
];

export const packagingmaterial = [
  {
    id: 1,
    model: "Steriking Pouches & Roll",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/wipak.png",
        model: "Steriking Rolls & Pouches",
        name: "Sterilization pouches & Rolls",
        main_points: [
          "Peel pouches & rolls",
          "Self-sealable peel pouches",
          "Strong seals & sealed edges",
          "Process Indicators",
          "Safe print design",
          "Strong & flexible multilayer films with medical grade paper",
          "Suitable for steam & gas sterilization",
          "Compliant with norms & standards",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/sterilization/img_006.png"],
        img2: ["/images/sterilization/images.png"],
        video: "",
      },
    ],
  },
  {
    id: 2,
    model: "Rotosealer",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/wipak.png",
        model: "Rotosealer",
        name: "Sealing machine",
        main_points: [
          "All-day readiness for use",
          "Energy saving when the machine is not in use",
          "All sealing process parameters are controlled",
          "Adjustable temperature rate",
          "Provide strong & impermeable seals",
          "Manufactured according to European safety regulations",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/sterilization/img_007.png"],
        img2: [""],
        video: "",
      },
    ],
  },
];
