export const oct = [
  {
    id: 1,
    model: "REVO FC 130",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "REVO FC 130",
        name: "The REVO FC 130 combines the world’s fastest SD-OCT with a non-mydriatic color fundus camera. Blaze through exams with a scan speed of 130,000 A-scans per second. Featuring our all-new AccuTrack™ real-time hardware-based eye tracker. Operating is as simple as the push of a button.",
        main_points: [
          "1,30,000 A-scan per second",
          "12.3 MP Fundus Camera",
          "Wide Retina and Anterior",
          "Glaucoma Tool Kit",
          "Wide & Dense OCT Angiography Scans",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 1,
              title: "ACCUTRACK",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/img2.png",
              desc: "Simplifying operation with the push of button to auto-position, auto-align, auto-focus and auto-capture",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/img3.png",
              desc: "An advanced artificial intelligence algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/center_logo.png",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "MOTION CORRECTION",
              img: "/images/rectina/img4.png",
              desc: "The software-based motion correction compensates for involuntary eye movements and blinks by capturing two scans and generating a motion corrected scan when necessary",
            },
            {
              id: 6,
              title: "STRUCTURE + FUNCTION (S+F)",
              img: "/images/rectina/img5.png",
              desc: "Comprehensive glaucoma solution that combines REVO OCT and PTS Visual Field results. S+F takes the diagnostic approach of the Hood report.",
            },
            {
              id: 7,
              title: "PROGRESSION ANALYSIS",
              img: "/images/rectina/img6.png",
              desc: "Gather baselines and follow-ups to monitor and manage disease progression in posterior and anterior scans",
            },
            {
              id: 8,
              title: "CONNECTIVITY",
              img: "/images/rectina/img7.png",
              desc: "A proficient networking solution with DICOM and EMR capabilities. Quickly and easily export to a desired location.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/FC130-New.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/FC130-New.png",
              "/images/rectina/oko_00313__Revo_FC130-scaled.png",
              "/images/rectina/REVO-FC-front-View.png",
              "/images/rectina/oko_00306__Revo_FC130-scaled.png",
              "/images/rectina/FC130-New.png",
            ],

            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaa",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
               {
                videothumbnail: "/images/cataract/video_2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
               {
                videothumbnail: "/images/cataract/video_2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, ",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "REVO FC ",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "REVO FC ",
        name: "The REVO FC 130 combines the ultra fast SD-OCT with a non-mydriatic color fundus camera. Featuring our all-new AccuTrack™ real-time hardware-based eye tracker. Operating is as simple as the push of a button.",
        main_points: [
          "80,000 A-scan per second",
          "12.3 MP Fundus Camera",
          "Wide Retina and Anterior Scans",
          "Glaucoma Tool Kit",
          "Dense OCT Angiography Scans",
        ],

        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 1,
              title: "ACCUTRACK",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/img2.png",
              desc: "Simplifying operation with the push of button to auto-position, auto-align, auto-focus and auto-capture",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/img3.png",
              desc: "An advanced artificial intelligence algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/center_logo.png",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "MOTION CORRECTION",
              img: "/images/rectina/img4.png",
              desc: "The software-based motion correction compensates for involuntary eye movements and blinks by capturing two scans and generating a motion corrected scan when necessary",
            },
            {
              id: 6,
              title: "STRUCTURE + FUNCTION (S+F)",
              img: "/images/rectina/img5.png",
              desc: "Comprehensive glaucoma solution that combines REVO OCT and PTS Visual Field results. S+F takes the diagnostic approach of the Hood report.",
            },
            {
              id: 7,
              title: "PROGRESSION ANALYSIS",
              img: "/images/rectina/img6.png",
              desc: "Gather baselines and follow-ups to monitor and manage disease progression in posterior and anterior scans",
            },
            {
              id: 8,
              title: "CONNECTIVITY",
              img: "/images/rectina/img7.png",
              desc: "A proficient networking solution with DICOM and EMR capabilities. Quickly and easily export to a desired location.",
            },
          ],
        },

        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/FC130-New.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/FC130-New.png",
              "/images/rectina/oko_00313__Revo_FC130-scaled.png",
              "/images/rectina/REVO-FC-front-View.png",
              "/images/rectina/oko_00306__Revo_FC130-scaled.png",
              "/images/rectina/FC130-New.png",
            ],

            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaa",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
               {
                videothumbnail: "/images/cataract/video_2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
               {
                videothumbnail: "/images/cataract/video_2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, ",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    model: "REVO 60",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "REVO 60",
        name: "Our supreme experience in Spectral Domain OCT allows us to provide the market REVO 60 with redesign optics. The updated REVO 60 meets all demands in daily routine practice.",
        main_points: [
          "60,000 A-scan per second",
          "Wide Retina and Anterior Scans",
          "Glaucoma tool kit",
          "Completely Automatic",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 1,
              title: "ACCUTRACK",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/img2.png",
              desc: "Simplifying operation with the push of button to auto-position, auto-align, auto-focus and auto-capture",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/img3.png",
              desc: "An advanced artificial intelligence algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/center_logo.png",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "MOTION CORRECTION",
              img: "/images/rectina/img4.png",
              desc: "The software-based motion correction compensates for involuntary eye movements and blinks by capturing two scans and generating a motion corrected scan when necessary",
            },
            {
              id: 6,
              title: "STRUCTURE + FUNCTION (S+F)",
              img: "/images/rectina/img5.png",
              desc: "Comprehensive glaucoma solution that combines REVO OCT and PTS Visual Field results. S+F takes the diagnostic approach of the Hood report.",
            },
            {
              id: 7,
              title: "PROGRESSION ANALYSIS",
              img: "/images/rectina/img6.png",
              desc: "Gather baselines and follow-ups to monitor and manage disease progression in posterior and anterior scans",
            },
            {
              id: 8,
              title: "CONNECTIVITY",
              img: "/images/rectina/img7.png",
              desc: "A proficient networking solution with DICOM and EMR capabilities. Quickly and easily export to a desired location.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/FC130-New.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/FC130-New.png",
              "/images/rectina/oko_00313__Revo_FC130-scaled.png",
              "/images/rectina/REVO-FC-front-View.png",
              "/images/rectina/oko_00306__Revo_FC130-scaled.png",
              "/images/rectina/FC130-New.png",
            ],

            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeeee.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeeee.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const perimeter = [
  {
    id: 1,
    model: "PTS 925W",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "PTS 925W",
        name: "The PTS 925W is our compact perimeter that packs a punch. It provides everything you need in our smallest footprint.",
        main_points: [
          "New 24-2C pattern now available",
          "Goldman III",
          "Voice Guidance",
          "ZETA™, ZETA™ FAST, and ZETA™ Faster",
          "DPA™ Defect Progression Analysis",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/Background.png",
          key_feature: [
            {
              id: 1,
              title: "FAST THRESHOLDING",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Using ZETA thresholding algorithms, test your patients in under 3 minutes",
            },
            {
              id: 2,
              title: "EYE TRACKING",
              img: "/images/rectina/center_logo.png",
              desc: "Monitor patient pupil movement during examination",
            },
            {
              id: 3,
              title: "BLINK DETECTION",
              img: "/images/rectina/center_logo.png",
              desc: "Our software detects when the eye is closed, and will not present stimuli to the patient",
            },
            {
              id: 4,
              title: "DPA DEFECT PROGRESSION ANALYSIS",
              img: "/images/rectina/center_logo.png",
              desc: "Create a baseline and follow disease progression over time",
            },
            {
              id: 5,
              title: "EYESEE",
              img: "/images/rectina/center_logo.png",
              desc: "In the review screen, click on any point during the exam to see the eye position reviewing the test result.",
            },
            {
              id: 6,
              title: "VOICE GUIDE",
              img: "/images/rectina/center_logo.png",
              desc: "Voice messages assist operator and patient during the examination",
            },
            {
              id: 7,
              title: "HEAD TRACKING",
              img: "/images/rectina/center_logo.png",
              desc: "Live monitoring and automated adjustments throughout exams",
            },
            {
              id: 8,
              title: "OPTIMIZED PERFORMANCE",
              img: "/images/rectina/center_logo.png",
              desc: "Optimized hardware and software integration ensures peak performance during high-demand scanning sessions.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "publications",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/20.-PTS-925W-(1).png"],
        video: [
          {
            imggallery: [
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
              
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
              
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
               
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
               
              },
            ],
           
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "PTS 925W",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "PTS 2000",
        name: "Treatment Table",
        main_points: [
          "Static and Kinetic Testing ",
          "Goldman I to V",
          "ZETA™, ZETA™ FAST, and ZETA™ Faster",
          "DPA™ Defect Progression Analysis",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/Background.png",
          key_feature: [
            {
              id: 1,
              title: "FAST THRESHOLDING",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Using ZETA thresholding algorithms, test your patients in under 3 minutes",
            },
            {
              id: 2,
              title: "EYE TRACKING",
              img: "/images/rectina/center_logo.png",
              desc: "Monitor patient pupil movement during examination",
            },
            {
              id: 3,
              title: "BLINK DETECTION",
              img: "/images/rectina/center_logo.png",
              desc: "Our software detects when the eye is closed, and will not present stimuli to the patient",
            },
            {
              id: 4,
              title: "DPA DEFECT PROGRESSION ANALYSIS",
              img: "/images/rectina/center_logo.png",
              desc: "Create a baseline and follow disease progression over time",
            },
            {
              id: 5,
              title: "EYESEE",
              img: "/images/rectina/center_logo.png",
              desc: "In the review screen, click on any point during the exam to see the eye position reviewing the test result.",
            },
            {
              id: 6,
              title: "VOICE GUIDE",
              img: "/images/rectina/center_logo.png",
              desc: "Voice messages assist operator and patient during the examination",
            },
            {
              id: 7,
              title: "HEAD TRACKING",
              img: "/images/rectina/center_logo.png",
              desc: "Live monitoring and automated adjustments throughout exams",
            },
            {
              id: 8,
              title: "OPTIMIZED PERFORMANCE",
              img: "/images/rectina/center_logo.png",
              desc: "Optimized hardware and software integration ensures peak performance during high-demand scanning sessions.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "publications",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/20.-PTS-925W-(1).png"],
        video: [
          {
            imggallery: [
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
              
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
              
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
               
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
               
              },
            ],
          },
        ],
      },
    ],
  },
];

export const tonometer = [
  {
    id: 1,
    model: "HS-A900 F900 Z800",
    data: [
      {
        id: 1,
        logo: "/images/rectina/CSO.png",
        model: "A900 F900 Z800",
        name: "CSO applanation Tonometers are precision devices for the measurement of IOP.",
        main_points: ["Precise & user friendly", "Easily mounted on Slit-lamp"],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: [
          "/images/Rectina/1.png",
          "/images/Rectina/2.png",
          "/images/Rectina/3.png",
        ],
        video: "",
      },
    ],
  },
  {
    id: 2,
    model: "FT 1000",
    data: [
      {
        id: 1,
        logo: "/images/rectina/TOMEY.png",
        model: "FT 1000",
        name: "Automated Air puff Non-Contact Tonometer",
        main_points: [
          "Fully automatic",
          "Touch screen",
          "Soft and silent air pulse",
          "Self diagnosis program",
          "Soft and silent air pulse",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/Rectina/FT-1000.png"],

        video: [
          {
            videoSrc: "/images/cataract/video_1.png",
            videoTitle: "",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
          },
        ],
      },
    ],
  },
];

export const pachymeter = [
  {
    id: 1,
    model: "Pachette 4",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "Pachette 4",
        name: "Gold standard in in desktop Pachymetry",
        main_points: [
          "Easy, Fast and Accurate",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/Pachette 4.png"],
        video: [
          "/images/rectina/Mask Group 38.png",
          "/images/rectina/Turn-on-Power-Pachette-4.png",
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Pachette 2",
    model: "Pachette 2",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "Pachette 2",
        name: "Handheld Pachymetry",
        main_points: [
          "Easy, Fast and Accurate",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/Pachmate-2.png"],
        video: [
         
{ videoData: [
  {
    videothumbnail: "/images/cataract/overview.png",
       videoUrl: "https://youtu.be/CuPHd0V9cgI",
    videoTitle: "Non-Myd Color Fundussss Camera",
  },
  {
    videothumbnail: "/images/cataract/video_2.png",
       videoUrl: "https://youtu.be/CuPHd0V9cgI",
    videoTitle: "Non-Myd Color Fundussss Camera",
  
  },
  {
    videothumbnail: "/images/cataract/overview.png",
       videoUrl: "https://youtu.be/CuPHd0V9cgI",
    videoTitle: "Non-Myd Color Fundussss Camera",
   
  },
  {
    videothumbnail: "/images/cataract/video_2.png",
       videoUrl: "https://youtu.be/CuPHd0V9cgI",
    videoTitle: "Non-Myd Color Fundussss Camera",
   
  },
],

}
         
        ],
      },
    ],
  },
];

export const erg = [
  {
    id: 1,
    model: "RETAVAL",
    data: [
      {
        id: 1,
        logo: "/images/rectina/cropped-cropped-LKC_logo_RGB_color_no_tagline_mini_400px.png",
        model: "RETAVAL",
        name: "Handheld ERG and VEP device.",
        main_points: [
          "Simple to use and interpret",
          "Advanced testing with ISCEV compliant",
          "No Corneal contact",
          "No dilation required",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publications",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/RETeval-full-side-view_pupil.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/RETeval-ganzfeld.png",
              "/images/rectina/RETeval-full-side-view_pupil.png",
              "/images/rectina/RETeval-front-view-eye.png",
              "/images/rectina/RETeval-ganzfeld.png",
            ],
            videoSrc: [
              "/images/rectina/erg1.png",
              "/images/rectina/erg2.png",
            ],
            videoTitle: "Simple To Use",
          },
        ],
      },
    ],
  },
  {
    id: 1,
    model: "EP-1000",
    data: [
      {
        id: 1,
        logo: "/images/rectina/TOMEY.png",
        model: "EP-1000",
        name: "EP-1000 is a diagnostic device for a complete examination of Retina function, the visual pathway and optic nerve.",
        main_points: [
          "Complies with ISCEV recommendation",
          "High-intensity LED colour flash and background illumination",
          "VEP, ERG, PERG, EOG, Sweep VEP, multi-channel VEP, S-Cone analysis, PERG ratio, VEP children, VEP uncooperative patient",
          "Multifocal (mf) ERG and VEP, short M-sequences",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publications",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/EP-1000Mf.png"],
        // video: [
        //   {
        //     imggallery: [
        //       "/images/rectina/RETeval-full-side-view_pupil.png",
        //       "/images/rectina/RETeval-full-side-view_pupil.png",
        //       "/images/rectina/RETeval-full-side-view_pupil.png",
        //     ],
        //     videoSrc: [
        //       "/images/rectina/Mask Group 38.png",
        //       "/images/rectina/Turn-on-Power-Pachette-4.png",
        //     ],
        //     videoTitle: "Overview Screen",
        //   },
        // ],
      },
    ],
  },
];

export const scanmate = [
  {
    id: 1,
    logo: "/images/rectina/DGH.png",
    model: "SCANMATE FLEX",
    name: "The Scanmate Flex is a portable ophthalmic ultrasound device. It is the perfect product to enhance your diagnostic practice.",
    main_points: [
      "Portable and USB connectivity",
      "Provides clear imaging of the posterior segment of the eye",
      "Seamless platform",
      "Seamless platform",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/rectina/Scanmate-FLEX.png"],
    video: [
      {
        imggallery: ["/images/rectina/DGH-SHOOT-3-042-UBM.png"],
        videoSrc: "/images/rectina/Scanmate FLEX.png",
        videoTitle: "Upgradable to A-scan and UBM",
           videoData: [
          {
            videothumbnail: "/images/cataract/overview.png",
               videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
            
          },
          {
            videothumbnail: "/images/cataract/overview.png",
               videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundussss Camera",
          
          },
          {
            videothumbnail: "/images/cataract/overview.png",
               videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
           
          },
          {
            videothumbnail: "/images/cataract/overview.png",
               videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
            
          },
        ],
      },
    ],
  },
];

export const ud = [
  {
    id: 1,
    logo: "/images/cataract/TOMEY.png",
    model: "UD-800",
    name: "Ultrasonic B-Scanner",
    main_points: [
      "Light & compact",
      "Wide touch screen",
      "Harmonic function",
      "Internal database",
      "Upgradable to A-Scan & UBM",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/rectina/UD-800_Side_0002.png"],
    video: [
      {
        imggallery: [
          "/images/rectina/UD-800_Side_0002.png",
          "/images/rectina/UD-800_Side_0002.png",
        ],
        video:[
          {  src:"https://youtu.be/AKa2ABRRgPs",
            title:"CBM ALTK+DSAEK System",
            description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
          },
          {  src:"https://youtu.be/AKa2ABRRgPs",
            title:"UT DSAEK Single Pass Short",
         description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "

          }
        ],
        videoSrc: "/images/cataract/overview.png",
        videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];

export const fundus = [
  {
    id: 1,
    logo: "/images/cataract/CSO.png",
    model: "COBRA + FUNDUS CAMERA",
    name: "Cobra+ is an easy to use, non-mydriatic digital fundus camera.",
    main_points: [
      "High resolution retinal images up to 50° visual field",
      "Multiple wavelength images",
      "Automatic measure of “Cup to disk ratio”",
      "Mosaic function",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/rectina/1.png"],
    video: [
      {
        imggallery: ["/images/rectina/1.png", "/images/rectina/2.png"],
        // videoSrc: "/images/cataract/overview.png",
        // videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];
