import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import Marquee from "react-marquee-slider";
import Aos from "aos";
import { microkeratome } from "../../../Data/CorneaRefractive";

const Microkeratome = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const handlePlay = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | Microkeratome"}
        centerHeading={"Microkeratome"}
      />

      <section
        className="w-full  md:py-96px-vw "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {microkeratome.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-[40%_60%] md:px-[7.29vw] gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw ">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{item}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:max-w-[90%] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full md:px-[7.29vw]  gap-x-16px-vw md:pt-[3vw]  mx-auto flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
    {/* slider */}
                 <div
                    className="w-full flex md:py-60px-vw items-center  justify-center gap-x-60px-vw"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                  >
                   
                    <Marquee
                      velocity={50}
                      minScale={0.8}
                      className="partner-marquee"
                      pauseOnHover={isPaused}
                    >
                      {item.images.map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`imgGallery-${index}`}
                          className="md:max-w-[24.23vw] md:px-20px-vw object-cover"
                        />
                      ))}
                    </Marquee>
                  </div>





            {item.video &&
              item.video.map((videoItem, index) => (
                <div
                  key={index}
                  className="w-full flex md:px-[7.29vw] flex-col items-center justify-center md:pt-[6.25vw]"
                >
                  <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                    IMAGE & VIDEO GALLERY
                  </h1>
                  <div className="md:mt-60px-vw flex flex-col items-start relative">
                    {/* Thumbnail */}
                    {!isPlaying ? (
                      <>
                        <img
                          src={videoItem.videoSrc} // Thumbnail image source
                          alt="video thumbnail"
                          className="md:max-w-[42.24vw] block"
                        />
                        <div
                          className="absolute cursor-pointer inset-0 flex items-center justify-center"
                          onClick={handlePlay} // Play the video on click
                        >
                          <img
                            src="/icon/playIcon.png" // Play icon
                            alt="play icon"
                            className="md:w-73px-vw"
                          />
                        </div>
                      </>
                    ) : (
                      // Video player
                      <iframe
                        className="md:max-w-[80vw] block"
                        width="700" // Increase the width here
                        height="450" // Adjust the height accordingly
                        src={`https://www.youtube.com/embed/${videoItem.videoUrl
                          .split("/")
                          .pop()}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}

                    <div className="md:pt-20px-vw text-[#2B2A29] font-poppins font-medium text-32px-vw">
                      {videoItem.videoTitle}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Microkeratome;
