// import React, { useEffect, useState } from "react";
// import SectionHeading from "../common/SectionHeading";
// import Aos from "aos";
// import Marquee from "react-marquee-slider";

// const Achievements = () => {
//   const [isPaused, setIsPaused] = useState(false);
//   const Achiement = [
//     {
//       id: "01",
//       img: "images/about/hero_award.png",
//     },
//     {
//       id: "02",
//       img: "images/about/ISOI_2023.png",
//     },
//     {
//       id: "03",
//       img: "images/about/Kerakon_2014_Sponsor.png",
//     },
//     {
//       id: "04",
//       img: "images/about/Kerakon_2023.png",
//     },
//     {
//       id: "05",
//       img: "images/about/Moria_2010.png",
//     },
//     {
//       id: "06",
//       img: "images/about/Moria_2011.png",
//     },
//     {
//       id: "07",
//       img: "images/about/Moria_2012.png",
//     },
//     {
//       id: "08",
//       img: "images/about/Moria_2013.png",
//     },
//     {
//       id: "09",
//       img: "images/about/Moria_2015.png",
//     },
//     {
//       id: "10",
//       img: "images/about/Moria.png",
//     },
//   ];

//   useEffect(() => {
//     Aos.init({
//       easing: "ease-in",
//       duration: 1500,
//       once: false,
//     });
//   }, []);
//   return (
//     <main
//       className="w-full"
//       data-aos="fade-up"
//       data-aos-duration="1500"
//       data-aos-offset="150"
//     >
//       <h1 className="w-full text-center md:my-30px-vw">
//         <SectionHeading title={"ACHIEVEMENTS"} />
//       </h1>

//       <div
//         className="w-full gap-x-[30px]"
//         onMouseEnter={() => setIsPaused(true)}
//         onMouseLeave={() => setIsPaused(false)}
//       > 
//       <div>
//         <Marquee
//           velocity={2}
//           minScale={0.8}
//           className="partner-marquee"
//           pauseOnHover={isPaused}
//         >
//           {Achiement.map((item, index) => (
//             <div key={index} className="w-full">
//               <div className="min-h-[23vw] opacity-60 rounded-[1.5vw]">
//                 <img
//                   src={item.img}
//                   alt={`Achievement ${item.id}`}
//                   className="w-full h-full object-cover rounded-[1.5vw]"
//                 />
//               </div>
//             </div>
//           ))}
//         </Marquee> 
//         </div>
//       </div>
//     </main>
//   );
// };

// export default Achievements;
import React, { useEffect, useState } from "react";
import SectionHeading from "../common/SectionHeading";
import Aos from "aos";
import Marquee from "react-marquee-slider";

const Achievements = () => {
  const [isPaused, setIsPaused] = useState(false);
  const Achiement = [
    {
      id: "01",
      img: "images/about/hero_award.png",
    },
    {
      id: "02",
      img: "images/about/ISOI_2023.png",
    },
    {
      id: "03",
      img: "images/about/Kerakon_2014_Sponsor.png",
    },
    {
      id: "04",
      img: "images/about/Kerakon_2023.png",
    },
    {
      id: "05",
      img: "images/about/Moria_2010.png",
    },
    {
      id: "06",
      img: "images/about/Moria_2011.png",
    },
    {
      id: "07",
      img: "images/about/Moria_2012.png",
    },
    {
      id: "08",
      img: "images/about/Moria_2013.png",
    },
    {
      id: "09",
      img: "images/about/Moria_2015.png",
    },
    {
      id: "10",
      img: "images/about/Moria.png",
    },
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <main
      className="w-full"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <h1 className="w-full text-center md:my-[40px]">
        <SectionHeading title={"ACHIEVEMENTS"} />
      </h1>

      <div
        className="w-full"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <Marquee
          velocity={10} /* Adjust speed here */
          minScale={0.8}
          className="w-full"
          pauseOnHover={isPaused}
        >
          {Achiement.map((item, index) => (
            <div key={index} className="w-[23vw] mx-[1vw]">
              {/* Adjust item width/margin here */}
              <div className="min-h-[23vw] rounded-[1.5vw]">
                <img
                  src={item.img}
                  alt={`Achievement ${item.id}`}
                  className="w-full h-full object-cover rounded-[1.5vw]"
                />
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </main>
  );
};

export default Achievements;
