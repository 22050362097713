import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";
import { opticalBiometerData } from "../../../Data/Cataract";
import BottomSlider from "../../slider/BottomSlider";

const OpticalBiometer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  const handlePlayClick = () => {
    setShowVideo(false);
  };

  const handleNextVideo = (item) => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % item.video.length);
  };

  const handlePrevVideo = (item) => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + item.video.length) % item.video.length
    );
  };

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cataract | Optical Biometer"}
        centerHeading={"Optical Biometer"}
      />

      <section
        className="w-full md:py-96px-vw md:px-[7.29vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {opticalBiometerData.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div>
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((point, index) => (
                  <div
                    key={index}
                    className="w-full flex items-center gap-x-20px-vw"
                  >
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{point}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw">
                {item.img.map((img, index) => (
                  <div key={index}>
                    <img
                      src={img}
                      alt=""
                      className="w-full md:max-w-[32.13vw] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full gap-x-16px-vw md:pt-[3vw] mx-auto flex items-center justify-center">
              <DownloadCard data={item.additionalInfo} />
            </div>

            {item.video && (
              <div className="w-full flex relative flex-col items-center justify-center md:pt-[6.25vw]">
                <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                  IMAGE & VIDEO GALLERY
                </h1>
                <div className="w-full flex md:pt-60px-vw md:pb-[12vw] items-center justify-center">
                  <img
                    src={item.video[currentIndex].videothumbnail}
                    alt="imgGallery"
                    className="md:max-w-[18.23vw]"
                  />
                </div>
                <div className="absolute left-0 md:w-full md:mb-40px-vw border-b-2 opacity-10 border-[#2B2A29] z-10"></div>

                {/* <div className="md:pt-60px-vw flex flex-col items-start relative">
                  <div className="flex items-center md:gap-x-30px-vw">
                    <div className="relative">
                      <img
                        src={item.video[currentIndex].imggallery}
                        alt="imggallery"
                        className="md:p-40px-vw w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                      <div className="absolute inset-0 flex w-full h-full items-center justify-center">
                        <div
                          className="border border-solid rounded-full border-white p-30px-vw cursor-pointer"
                          onClick={() => handlePrevVideo(item)}
                        >
                          <BsArrowLeft className="md:text-[2vw] text-white" />
                        </div>
                      </div>
                    </div>

                    <div>
                      <img
                        src={item.video[currentIndex].videoSrc}
                        alt="video"
                        className="md:max-w-[42.24vw] block"
                      />
                      <div className="md:pt-20px-vw text-[#2B2A29] font-poppins font-medium text-32px-vw">
                        {item.video[currentIndex].videoTitle}
                      </div>
                    </div>

                    <div className="relative">
                      <img
                        src={item.video[currentIndex].videothumbnail}
                        alt="imggallery"
                        className="md:p-40px-vw w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                      <div className="absolute inset-0 flex w-full h-full items-center justify-center">
                        <div
                          className="border border-solid rounded-full border-white p-30px-vw cursor-pointer"
                          onClick={() => handleNextVideo(item)}
                        >
                          <BsArrowRight className="md:text-[2vw] text-white" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="absolute cursor-pointer inset-0 flex items-center justify-center"
                    onClick={handlePlayClick}
                  >
                    <img
                      src="/icon/playIcon.png"
                      alt="play icon"
                      className="md:w-73px-vw"
                    />
                  </div>
                </div> */}

                {showVideo && (
                  <BottomSlider slides={item.video}/>

                  // <div className="absolute inset-0 bottom-0 left-0 md:mt-[18vw] flex w-full h-full items-center justify-center">
                  //   <iframe
                  //     width="680"
                  //     height="500"
                  //     src={`https://www.youtube.com/embed/${item.video[currentIndex].videoId}`}
                  //     title="YouTube video player"
                  //     frameBorder="0"
                  //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  //     allowFullScreen
                  //   ></iframe>
                  //   <button
                  //     onClick={() => setShowVideo(false)}
                  //     className="absolute top-4 right-4 text-white text-2xl"
                  //   >
                  //     &times;
                  //   </button>
                  // </div>
                )}
              </div>
            )}
          </div>
        ))}
      </section>
    </main>
  );
};

export default OpticalBiometer;
