import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useRef, useEffect } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import TestimonialSlider from "./TestimonialSlider";
import Slider from "react-slick";
import Aos from "aos";

const Testimonial = () => {
  const sliderRef = useRef(null);

  // Testimonial data array
  const testimonials = [ 
    {
      quote:
        "I have used My Healthskape products from the inception of my clinic-Darshan Eye care and Surgical Centre -Chennai, in 2006, and am extremely satisfied with the organization, their products and the service support. I have their equipment in both the Out-patient service and the Operation theatre and these include, vision chart projectors, slit lamps, specular microscope, pachymeter, visual field analyzer, digital biometer, autorefractokeratometer, tomography, optical coherence tomography, sterilizers, packing rolls, etc.", 

      desc: "A special word about the Photo-Video slit lamps that they provide from CSO - I have three of these and find them extremely easy to use, cost effective and of excellent quality. The photographs are of great quality and have a proper indexing system that allows for easy archival and retrieval. The videos are also easy to take and are of good quality  I have a tertiary ocular surface clinic and manage complicated problems of the same. I find their Idra ocular surface analyzer easy to use and provides good quality information that is in line with clinical findings and the documentation is neat and easy to understand-both for the clinician and the patient. For patients with severe meibomian gland dysfunction which is refractory to medical treatment, their E-eye device is very useful and effective.As an Organization, My Healthskape is very consumer friendly and easily accessible - both from the local service support team and the administration in Mumbai. I have had no problems at all with them during my long association and am happy to recommend them and their products to my friends.",
      imgsrc: "/images/home/DR SRINIVAS RAO.png",
      name: "DR SRINIVAS RAO",
      designation: "DO, DNB ,FRCSEd Cataract Corneal & Refractive Surgeon",
      location:"DARSHAN EYE CLINIC"
    },
    {
      quote:
        "It is my pleasure to comment that we as a team of Dhami Eye Care Hospital are highly pleased with the quality of service provided by My Healthskape. We sincerely appreciate your responsibilities and the way your team give us the maintenance services of our equipment since last 25 yrs. We recommended My Healthskape to all as a supplier and for maintenance service. The Engineers are well trained and the application persons are perfect with latest knowledge of the products and clinical applications needed. All these boys are available a telephone call when away. The breakdown of any machine is put to order immediately. There has never been a loss of period of work.",
      desc: "We have a 25 yrs. association with My Healthskape. We always feel like a family and friend with My Healthskape, never on terms of business.We wish all the best for all members of My Healthskape family. Undoubtly they are the best.",
      imgsrc: "/images/home/DR GS DHAMI.png",
      name: "DR GS DHAMI",
      designation: "MBBS, M.D (Ophth.) ,F.I.C.O (Japan)", 
      location:"DHAMI EYE CARE HOSPITAL"
    },
    {
      quote:
        "Revo 27 User ",
      desc: "Excellent OCT in the market. ●	Best speed ●	User friendly ●	High resolution OCT scans of the retina and anterior structures ●	Best service",
      imgsrc: "/images/home/DR SANJAY SAVANI.png",
      name: "DR SANJAY SAVANI",
      designation: "MBBS, M.S. Opthal",
      location:"SAVANI EYE HOSPITAL"
    },
  
    {
      quote:
        "I wanted to share that we've been using the MS-39 from CSO and are really impressed with its performance and reliability. ",
      desc: "",
      imgsrc: "/images/home/GANESH.png",
      name: "Dr Sri Ganesh",
      designation: "MBBS, MS, DNB, DSC (Hon), FRCS (Gla), FAICO (Hon), FWRCS HOD Phaco & Refractive Services", 
      location:"Nethradhama Super Speciality Eye Hospital, Bangalore ",
    },
    {
      quote:
        "We are thrilled to share my exceptional experience with the Revo FC 130 OCT angiography machine. The image quality it captures is truly outstanding, providing unparalleled clarity and detail that are crucial for making precise diagnoses in my daily practice. The intuitive software and user-friendly interface allow for efficient and streamlined examinations, maximizing patient comfort and clinic workflow.",
      desc: "Beyond the exceptional image quality and user experience, the Revo FC 130 stands out for its robust hardware design. Its reliable performance ensures consistent, dependable operation, which is vital in a busy clinical setting.The machine's durability fosters peace of mind, knowing it can withstand the demands of everyday use. I would also like to commend the excellent service team behind the Revo FC 130. Their prompt and helpful support has been invaluable, ensuring any questions or concerns are addressed swiftly and efficiently.The exceptional combination of superior technology, user-friendly design, and outstanding service makes the Revo FC 130 a truly remarkable OCT/angiography machine.",
      imgsrc: "/images/home/dr_shyamal.png",
      name: "Dr Shyamal Dwivedi",
      designation: "Vitreoretinal consultant", 
      location:"Raghudeep Eye Hospital"
    },
  ];

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true, // Center the active slide
    centerPadding: "10%", // Partially show the next/previous slides
    slidesToScroll: 1,
    arrows: true, // Use custom buttons
    nextArrow: <BsArrowRight />,
    prevArrow: <BsArrowLeft />,
    beforeChange: (current, next) => {
      const allSlides = document.querySelectorAll(".slick-slide");
      allSlides.forEach((slide) => {
        slide.style.opacity = "0.4"; // Set opacity of all slides to less
      });
    },
    afterChange: (current) => {
      const activeSlide = document.querySelector(
        `.slick-slide[data-index="${current}"]`
      );
      if (activeSlide) {
        activeSlide.style.opacity = "1"; // Keep active slide full opacity
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1, // Show one slide
          centerMode: false,
        },
      },
    ],
  };

  useEffect(() => {
    // Initialize with active slide having full opacity
    const activeSlide = document.querySelector(`.slick-slide[data-index="0"]`);
    if (activeSlide) {
      activeSlide.style.opacity = "1";
    }
  }, []);

  const handlePrev = () => sliderRef.current.slickPrev();
  const handleNext = () => sliderRef.current.slickNext();

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <section
      className="w-full relative md:pl-[7.29vw] px-4 py-80px-mvw   md:py-[11vh] grid md:grid-cols-[35%_65%] grid-cols-1"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="flex flex-col items-start  gap-y-30px-vh">
        <div>
          <SectionHeading
            title={"SPEAK TO OUR EXPERT"}
            customCss={"uppercase md:max-w-[80%]"}
          />
        </div>
        <div className="w-full md:max-w-[20.73vw] max-w-full">
          <ParaText
            paratext={
              "Experience advanced technology firsthand. Schedule an in-person demonstration with our expert to discover the benefits our products offer."
            }
          />
        </div>

        <div className="rounded-[4vw] border-[1px] border-[#2B2A29] md:flex items-center justify-center px-24px-vw py-18px-vh hidden  cursor-pointer mt-30px-vh">
          <div className="inline-flex text-center gap-x-10px-vw">
            <button
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-40px-vw opacity-[0.8]"
              onClick={handlePrev}
            >
              <BsArrowLeft />
            </button>
            <button
              className="text-[#2B2A29] font-poppins md:text-[1.24vw] text-40px-vw opacity-[0.8]"
              onClick={handleNext}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="w-full">
        <Slider ref={sliderRef} {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <TestimonialSlider
              key={index}
              testimonial={testimonial.quote}
              desc={testimonial.desc}
              imgsrc={testimonial.imgsrc}
              title={testimonial.name}
              designation={testimonial.designation} 
              location={testimonial.location}
            />
          ))}
        </Slider>

        <button className="w-full md:hidden flex items-center mt-20px-mvw  justify-center">
          <div className="rounded-[8vw] flex items-center border-[0.5px] border-[#2B2A29] opacity-100 px-[24px] py-[12px] space-x-[17px]">
            <div
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-[20px] opacity-[0.8]"
              onClick={handlePrev}
            >
              <BsArrowLeft />
            </div>
            <div
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-[20px] opacity-[0.8]"
              onClick={handleNext}
            >
              <BsArrowRight />
            </div>
          </div>
        </button>
      </div>
    </section>
  );
};

export default Testimonial;
