import React from "react";
import ParaText from "../common/ParaText";

const TestimonialSlider = ({
  testimonial,
  desc,
  imgsrc,
  title,
  designation,
  location,
}) => {
  return (
    <div className="flex flex-col items-start justify-start md:gap-y-22px-vh gap-y-15px-mvw pt-40px-vh md:pt-0  md:pr-[5.8vw]">
      <div className="md:w-60px-vw w-25px-mvw">
        <img
          src="/images/home/inverted_comma.png"
          alt="inverted_comma"
          className="w-full h-full object-cover"
        />
      </div>
      <div>
        <blockquote className="text-[#2B2A29] opacity-100 md:text-24px-vw text-18px-mvw font-poppins font-semibold  mt-10px-vh">
          {testimonial}
        </blockquote>
      </div>
      <div className="mt-8px-mvw md:mt-0">
        <ParaText
          paratext={desc}
          CustomCss={"md:text-20px-vw  opacity-[0.4] md:w-11/12 "}
        />
      </div>
      <div className="flex md:gap-x-30px-vw gap-x-12px-mvw items-center md:mt-10px-vh">
        <div className="md:w-[6.77vw] w-[18.13vw]">
          <img src={imgsrc} alt={title} />
        </div>
        <div className="flex flex-col items-start justify-center gap-y-8px-vh">
          <h2 className="text-[#2B2A29] md:text-20px-vw text-14px-mvw text-14px-mvw font-poppins font-medium">
            {title}
          </h2>
          <p className="opacity-[0.6] text-[#2B2A29] font-poppins font-regular text-12px-mvw md:text-16px-vw">
            {designation}
          </p>
          <p className="opacity-[0.6] text-[#2B2A29] font-poppins font-regular text-12px-mvw md:text-16px-vw">
            {location}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
