import React from "react";
import { Link } from "react-router-dom";

const ListingCards = ({ data }) => {
  return (
    <main className="w-full grid md:grid-cols-3 grid-cols-1 my-80px-vh md:gap-20px-vw gap-20px-mvw px-16px-mvw md:px-[7.29vw] ">
      {data.map((item, index) => (
        <Link key={index} to={item.link} className="">
          <div className="border-[#707070] h-full   w-full relative hover:border-none border-[1px] border-solid border-opacity-40 rounded-[20px] flex flex-col items-start font-poppins font-medium space-y-12px-vh  md:text-32px-vw text-18px-mvw md:p-40px-vw p-26px-mvw transition-all duration-300 cursor-pointer hover:bg-white  ">
            <div className="flex items-center justify-between">
              <div>
                <div>{item.id}</div>
                <div>{item.name}</div>
              </div>
              <div className="p-40px-vw">
                <img src={item.logo} alt="" />
              </div>
            </div>

            <div className="w-full h-full   flex items-center justify-center">
              <img
                src={item.img}
                alt=""
                className="object-contain w-[90%]"
              />
            </div>

            {/* Arrow hidden initially, visible on hover */}
            <div className="absolute bottom-0 right-0 opacity-0 hover:opacity-100 transition-opacity duration-300">
              <img
                src="/images/category/active_arrow.svg"
                alt="arrow"
                loading="lazy"
              />
              <div className="absolute right-20px-vh bottom-36px-vh">
                <img
                  src="/images/category/arrow.png"
                  alt="arrow"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </Link>
      ))}
    </main>
  );
};

export default ListingCards;
