import { RxHamburgerMenu } from "react-icons/rx"; 
import { CgSearch } from "react-icons/cg"; 
import { CiSearch } from "react-icons/ci"; 
import { FiSearch } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { navlinks, ourProductsSublinks } from "../../Data/Navliks";
import { CgChevronDown, CgClose, CgMenu } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [productsHover, setProductsHover] = useState(false);
  const [hoveredSublink, setHoveredSublink] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [productsMenuOpen, setProductsMenuOpen] = useState(false); // State for products dropdown

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const threshold = window.innerHeight * 0.8;

      if (scrollY > threshold) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`${
        isSticky ? "fixed top-0  left-0 right-0" : "absolute  left-0 right-0"
      } z-30 md:mx-auto   top-40px-vh md:w-11/12 w-full rounded-lg transition-all duration-300 ${
        isSticky
          ? "bg-white bg-opacity-5 text-black backdrop-blur-xl shadow-lg"
          : "bg-white/10 text-white backdrop-blur-26"
      }`}
    > 
    

      <nav className="w-full flex justify-between items-center  px-[2.8vw] py-8px-vw">
        {/* logo */}
        <Link to="/">
          <div className="flex flex-col justify-center items-center gap-y-[0.5vh] p-4px-vh">
            {isSticky ? (
              <div>
                <div className="md:w-[8vw] w-[20.93vw]">
                  <img
                    src="/logo/sticky_logo.svg"
                    alt="footer_logo"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-y-[0.5vh] items-center text-center">
                <div className="md:w-[4.69vw] w-[8vw]">
                  <img
                    src="/logo/logo_top.png"
                    alt="logo"
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                </div>
                <div className="md:w-[10.16vw] w-[20vw] text-black">
                  <img
                    src="/logo/logo_middle.png"
                    alt="logo"
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                </div>
                <div className="md:w-[10.16vw] w-[20vw]">
                  <img
                    src="/logo/logo_bottom.png"
                    alt="logo"
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                </div>
              </div>
            )}
          </div>
        </Link>

        <div className="md:hidden  flex items-center space-x-4">
          {/* Search Icon */}
          <CgSearch 
            className={`${
              isSticky ? "text-black" : "text-white"
            } w-[7vw] h-[7vw]`}
          /> 
         

          {/* Hamburger Icon */}
          <button onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? (
              <CgClose
                className={`${
                  isSticky ? "text-black" : "text-white"
                } w-[8vw] h-[8vw]`}
              />
            ) : (
              <RxHamburgerMenu 
                className={`${
                  isSticky ? "text-black" : "text-white"
                } w-[8vw] h-[8vw]`}
              />
            )}
          </button>
        </div>

        {/* navlinks for desktop */}
        <div className="hidden md:flex items-center whitespace-nowrap font-poppins font-medium uppercase text-18px-vw space-x-65px-vw">
          {navlinks.map((item) => (
            <div
              key={item.name}
              className="relative flex items-center"
              onMouseEnter={() =>
                item.name === "Our Products" && setProductsHover(true)
              }
              onMouseLeave={() =>
                item.name === "Our Products" && setProductsHover(false)
              }
            >
              <Link to={item.link} className="flex items-center">
                <ul
                  className={`${
                    isSticky ? "text-black" : "text-white"
                  } text-18px-vw tracking-wide`}
                >
                  {item.name}
                </ul>
                {item.name === "Our Products" && (
                  <span className="ml-1">
                    <CgChevronDown size={25} />
                  </span>
                )}
              </Link>

              {/* Dropdown for Our Products */}
              {item.name === "Our Products" && (
                <div
                  className={`absolute top-full left-0 mt-46px-vw bg-white text-[#2B2A29] font-poppins font-medium text-18px-vw opacity-100  rounded-bl-lg rounded-br-lg w-[20vw] py-[1.5vh] transform transition-all duration-300 ease-in-out ${
                    productsHover
                      ? "opacity-100 translate-y-0 visible"
                      : "opacity-0 invisible -translate-y-4"
                  }`}
                  onMouseEnter={() => setProductsHover(true)}
                  onMouseLeave={() => setProductsHover(false)}
                >
                  {ourProductsSublinks.map((sublink) => (
                    <Link
                      key={sublink.id}
                      to={sublink.link}
                      className="flex justify-between items-center px-24px-vw py-[1vh] "
                      onMouseEnter={() => setHoveredSublink(sublink.id)}
                      onMouseLeave={() => setHoveredSublink(null)}
                    >
                      <span>{sublink.name}</span>
                      {hoveredSublink === sublink.id && (
                        <img
                          src="/icon/sublink_icon.svg"
                          alt="sublinkicon"
                          loading="lazy"
                        />
                      )}
                    </Link>
                  ))}
                </div>
              )} 


              
            </div>
          ))}

          <div className="relative w-[21.875vw] h-full flex items-center">
            <input
              className={`w-full pl-52px-vw py-13px-vh h-[5vh]  tracking-wider opacity-100 font-gotham  font-book rounded-full bg-transparent brightness-105 ${
                isSticky
                  ? "border-black text-black placeholder-black  border-solid border-[0.5px]"
                  : "border-white text-white placeholder-white  border-solid border-[0.5px]"
              } outline-none   transition-all duration-300 ease-in-out text-16px-vw`}
              type="text"
              name="search"
              placeholder="Search..."
            />
            <div className="absolute left-22px-vw top-1/2 transform -translate-y-1/2">
              <div
                className={`${
                  isSticky ? "text-black" : "text-white"
                } w-20px-vw`}
              >
                <FiSearch className="w-full h-full object-cover" />
              </div>
            </div>
          </div>
        </div>
      </nav> 
     

      {/* Mobile Sidebar */}
      {menuOpen && (
        <div className="fixed w-full  inset-0 z-[-1]  md:hidden">
          <div
            className=" w-full  px-20px-mvw h-[140vw] pt-100px-mvw"
            style={{
              backgroundImage: `url("/images/home/Home_bg.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="  rounded-lg space-y-20px-mvw w-full ">
              {" "}
              {/* Add max-width for better display */}
              {navlinks.map((item) => (
                <div key={item.name} className="relative">
                  <button
                    className="flex justify-between w-full items-center pb-18px-mvw text-18px-mvw text-[#FFFFFF] brightness-150 font-poppins font-medium uppercase"
                    onClick={() => {
                      if (item.name === "Our Products") {
                        setProductsMenuOpen(!productsMenuOpen);
                      }
                    }}
                  >
                    <span>{item.name}</span>
                    <div className="md:hidden block absolute left-0 bottom-0  border-white w-full  md:mx-[6.24vw]   border-b-[0.53vw] opacity-20 z-10"></div>
                    {item.name === "Our Products" && (
                      <CgChevronDown
                        size={20}
                        className={`transform transition-transform duration-300 ${
                          productsMenuOpen ? "rotate-180" : ""
                        }`}
                      />
                    )}
                  </button>
                  {/* Mobile Dropdown for Our Products */}
                  {item.name === "Our Products" && productsMenuOpen && (
                    <div className="mt-2 flex flex-col">
                      {ourProductsSublinks.map((sublink) => (
                        <Link
                          key={sublink.id}
                          to={sublink.link}
                          className="px-4 py-2 text-[#FFFFFF] brightness-150 font-poppins font-regular pb-12px-mvw text-16px-mvw transition duration-300"
                        >
                          {sublink.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
