import React, { useEffect } from "react";
import SectionHeading from "../common/SectionHeading";
import Aos from "aos";

const OurTeam = () => {
  const TeamData = [
    {
      id: 1,
      name: "MONISH BACHOO",
      img: "/images/about/MONISH.png",
    },
    {
      id: 2,
      name: "SAMEER PAIGANKAR",
      img: "/images/about/Sameer.png",
    },
    {
      id: 3,
      name: "SHEELA PAIGANKAR",
      img: "/images/about/Sheela.png",
    },
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div
      className="w-full md:px-[7.29vw]"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <SectionHeading
        title={"OUR TEAM"}
        customCss={"text-center md:mb-60px-vw md:mt-100px-vw"}
      />

      <div className="w-full grid  md:grid-cols-3 grid-cols-1 gap-x-16px-vw">
        {TeamData.map((item) => (
          <div
            key={item.id}
            className="flex    flex-col items-start gap-y-20px-vw justify-start"
          >
            <div className="border cursor-pointer relative hover:bg-white border-solid border-[#707070] border-opacity-40 rounded-[1.5vw]">
              <img
                src={item.img}
                alt="doctor"
                className="w-full h-full object-cover"
              />

              <div className="absolute bottom-0 right-0 opacity-0 hover:opacity-100 transition-opacity duration-300">
                <img
                  src="/images/category/active_arrow.svg"
                  alt="arrow"
                  loading="lazy"
                />
                <div className="absolute right-20px-vh bottom-36px-vh">
                  <img
                    src="/images/category/arrow.png"
                    alt="arrow"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="font-poppins md:ml-[0.5vw] font-medium text-[#2B2A29] text-32px-vw">
              {item.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
