import React, { useEffect } from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import SectionHeading from "../../components/common/SectionHeading";
import ParaText from "../../components/common/ParaText";
import DownloadCard from "../../components/common/DownloadCard";
import Aos from "aos";

const Investors = () => {
  const InvestorsData = [
    {
      id: 1,
      title: "Form MGT-7 FY 2018-19",
    },
    {
      id: 2,
      title: "Form MGT-7 FY 2019-20",
    },
    {
      id: 3,
      title: "Form MGT-7 FY 2020-21",
    },
    {
      id: 4,
      title: "Form MGT-7 FY 2021-22",
    },
    {
      id: 5,
      title: "Form MGT-7 FY 2022-23",
    },
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <section>
      <CategoryTopSection
        breadcrumb={"Home | Investors"}
        centerHeading={"Investors"}
      />

      <main
        className="grid grid-cols-1 md:px-[7.29vw] md:py-[6vw] w-full "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="flex flex-col items-start">
          {/* <SectionHeading title={"OUR INVESTORS"} />
          <ParaText
            paratext={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
            }
            CustomCss={"md:mt-32px-vw"}
          /> */}
          <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-16px-vw pt-60px-vw">
            <DownloadCard data={InvestorsData} />
          </div>
        </div>
      </main>
    </section>
  );
};

export default Investors;
