import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { surgical } from "../../../Data/Cataract";

const Surgical = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cataract | Surgical Blades"}
        centerHeading={"Surgical Blades"}
      />

      <section
        className="w-full  md:py-96px-vw md:px-[7.29vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {surgical.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="flex items-start justify-start">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-10/12 h-auto object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{item}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:max-w-[30.13vw] md:max-h-[30vw]  object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            <>
              <div className="w-full gap-x-16px-vw md:pt-[3vw] mx-auto flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
              {item.video &&
                item.video.map((videoItem, index) => (
                  <div
                    key={index}
                    className="w-full flex flex-col items-center justify-center md:pt-[6.25vw]"
                  >
                    <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                      IMAGE & VIDEO GALLERY
                    </h1>
                    <div className="md:mt-60px-vw flex flex-col items-start relative">
                      {/* Only Image */}
                      <img
                        src={videoItem.videoSrc} // Thumbnail image source
                        alt="video thumbnail"
                        className="md:max-w-[42.24vw] block"
                      />
                    </div>
                  </div>
                ))}
            </>
          </div>
        ))}
      </section>
    </main>
  );
};

export default Surgical;
