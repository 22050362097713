import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { scanmate } from "../../../Data/RectinaGlaucoma";

const Scanmate = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | B-Scan"}
        centerHeading={"B-Scan"}
      />

      <section
        className="w-full  md:py-96px-vw md:px-[7.29vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {scanmate.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-10/12  h-auto object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{item}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-centergap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:max-w-[25.13vw] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full  gap-x-16px-vw md:pt-[3vw]  mx-auto flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
            {item.video &&
              item.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[6.25vw]">
                  <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                    IMAGE & VIDEO GALLERY
                  </h1>
                  <div className="w-full flex items-center md:py-60px-vw">
                    {item.imggallery.map((img) => (
                      <div className="flex justify-center md:p-40px-vw w-full items-center">
                        <img src={img} alt="" className="object cover md:w-[25vw]" />
                      </div>
                    ))}
                  </div>

                  <div className="absolute left-0 md:w-full  md:mt-40px-vw   border-b-2 opacity-10  border-[#2B2A29] z-10"></div>

                  <div className="md:pt-60px-vw flex flex-col items-start relative">
                    <div className="flex items-center md:gap-x-30px-vw">
                      {/* <div className="relative">
                        <img
                          src={item.imggallery}
                          alt="imggallery"
                          className="md:p-40px-vw w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                        <div className="absolute inset-0  flex w-full h-full items-center justify-center ">
                          <div className=" border border-solid rounded-full border-white p-30px-vw">
                            <BsArrowLeft className="md:text-[2vw] text-white" />
                          </div>
                        </div>
                      </div> */}

                      <div>
                        <img
                          src={item.videoSrc}
                          alt="video"
                          className="md:max-w-[42.24vw] block"
                        />
                        <div className=" md:pt-20px-vw text-[#2B2A29] font-poppins font-medium text-32px-vw">
                          {item.videoTitle}
                        </div>
                      </div>

                      {/* <div className="relative">
                        <img
                          src={item.imggallery}
                          alt="imggallery"
                          className="md:p-40px-vw w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                        <div className="absolute inset-0  flex w-full h-full items-center justify-center ">
                          <div className=" border border-solid rounded-full border-white p-30px-vw">
                            <BsArrowRight className="md:text-[2vw] text-white" />
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="absolute cursor-pointer inset-0 flex items-center justify-center">
                      <img
                        src="/icon/playIcon.png"
                        alt="play icon"
                        className="md:w-73px-vw "
                      />{" "}
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Scanmate;
