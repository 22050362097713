import React, { useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const BottomSlider = ({slides}) => {
//   const slides = [
//     {
//       id: 1,
//       image: "/images/category/1.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 1: High-Resolution Retina Imaging",
//       description: "Experience the ultimate clarity and precision with high-resolution retina imaging tools.",
//     },
//     {
//       id: 2,
//       image: "/images/category/2.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 2: Advanced Eye Diagnostics",
//       description: "Explore cutting-edge diagnostic tools for comprehensive eye care.",
//     },
//     {
//       id: 3,
//       image: "/images/category/3.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 3: Next-Gen Vision Care",
//       description: "Revolutionize eye care with next-generation tools for improved vision solutions.",
//     },
//     {
//       id: 4,
//       image: "/images/category/4.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 4: Precise Retinal Analysis",
//       description: "Gain accurate and detailed retinal insights with advanced tools.",
//     },
//     {
//       id: 5,
//       image: "/images/category/5.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 5: Innovative Eye Tools",
//       description: "Discover innovative eye tools designed to transform the future of eye care.",
//     },
//   ];
// const slides = [
//     {
//       videothumbnail: "/images/cataract/overview.png",
//       videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       videoTitle: "Non-Myd Color Fundus Cameraaaa",
//       videoDesc:
//         "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
//     },
//     {
//       videothumbnail: "/images/cataract/overview.png",
//       videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       videoTitle: "Non-Myd Color Fundus Camera",
//       videoDesc:
//         "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
//     },
//     {
//       videothumbnail: "/images/cataract/overview.png",
//       videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       videoTitle: "Non-Myd Color Fundus Camera",
//       videoDesc:
//         "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
//     },
//   ]

  const [activeIndex, setActiveIndex] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    setIsVideoPlaying(false);
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    setIsVideoPlaying(false);
  };

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
  };

  return (
    <div className="md:mt-[7vw] pb-[6.25vw] flex flex-col items-center relative">
      <div className="flex items-center gap-[0.83vw]">
        {/* Left Slide */}
        <div className="relative h-[26.04vw] w-[20.73vw]">
          <img
            src={slides[(activeIndex - 1 + slides.length) % slides.length].videothumbnail}
            alt="Previous"
            className="w-full h-full object-cover rounded-[1.5vw]"
          />
          <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-80"></div>
          <div className="absolute inset-0 flex items-center justify-center">
          <button
  className="w-[6.67vw] h-[6.67vw] rounded-full border-[2px] border-white backdrop-blur-xl flex items-center justify-center shadow-md"
  onClick={handlePrev}
>
  <BsArrowLeft className="text-[3vw] font-reguler text-white" />
</button>

          </div>
        </div>

        {/* Center/Active Slide */}
        <div className="relative h-[29.17vw] max-w-[42.24vw] w-[42.24vw]  flex flex-col items-start justify-between">
          <div className="h-[29.17vw] w-full flex items-center justify-center">
            {isVideoPlaying ? (
              <iframe
                src={slides[activeIndex].videoSrc}
                title="Video Player"
                className="w-full h-[29.17vw] rounded-[1.5vw]"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
            ) : (
                <div
                className="relative w-full h-full cursor-pointer"
                onClick={handlePlayVideo}
              >
                <img
                  src={slides[activeIndex].videothumbnail}
                  alt="Active"
                  className="w-full h-[29.17vw]  min-h-[29.17vw] object-contain rounded-[1.5vw]"
                />
                {slides[activeIndex].videoSrc && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <img
                      src="/icon/playIcon.png"
                      alt="Play Icon"
                      className="w-[3.5vw] h-[3.5vw]"
                    />
                  </div>
                )}
              </div>
              
            )}
          </div>
          {
            slides[activeIndex].videoTitle &&
            <div className=" text-[#2B2A29] pt-20px-vw font-poppins font-medium text-32px-vw">
            {slides[activeIndex].videoTitle}
          </div>
         
          }
          {  slides[activeIndex].videoDesc &&
             <div className="text-[#2B2A29] pt-12px-vw font-regular font-poppins text-20px-vw opacity-80">
             {slides[activeIndex].videoDesc}
           </div>
          }
        </div>

        {/* Right Slide */}
        <div className="relative h-[26.04vw] w-[20.73vw]">
          <img
            src={slides[(activeIndex + 1) % slides.length].videothumbnail}
            alt="Next"
            className="w-full h-full object-cover rounded-[1.5vw]"
          />
          <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-80"></div>
          <div className="absolute inset-0 flex items-center justify-center">
          <button
  className="w-[6.67vw] h-[6.67vw] rounded-full border-[2px]  border-white  backdrop-blur-xl bg-transparent flex items-center justify-center shadow-md"
  onClick={handleNext}
>
  <BsArrowRight className="text-[3vw] font-reguler text-white" />
</button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomSlider;
