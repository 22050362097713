export const topographer = [
  {
    id: 1,
    model: "ANTARES +",
    data: [
      {
        id: 1,
        logo: "/images/cataract/cso.png",
        model: "ANTARES + Corneal Topographer",
        // name: "Treatment Table",
        main_points: [
          "Various maps",
          "Keratoconus screening",
          "Videokeratoscopy",
          "Pupillography",
          "Contact lens application",
          "Dry eye report",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/ANTARES-0007.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeeee.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Contact Lens Application",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "SIRIUS +",
    data: [
      {
        id: 1,
        logo: "/images/cataract/cso.png",
        model: "SIRIUS +",
        name: "Scheimpflug with Placido disk Topography",
        main_points: [
          "Measurements up to 100 HR corneal sections.",
          "Various maps (Front & Back of the cornea)",
          "Early Keratoconus screening with ABCD grading scale",
          "Ambrosio pachymetry progression map",
          "Corneal aberrometer",
          "Glaucoma screening",
          "Pupillography",
          "IOL calculation with Ray tracing",
          "Intrastromal ring",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/cso_16075055325571.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Compact Setup",
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Contact Lens Application",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeeee.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Contact Lens Application",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Contact Lens Application",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Contact Lens Application",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    model: "TMS-4N",
    data: [
      {
        id: 1,
        logo: "/images/cataract/TOMEY.png",
        model: "TMS-4N",
        name: "Corneal Topographer",
        main_points: [
          "Built-in LCD screen",
          "Various maps",
          "Keratoconus screening",
          "Fourier analysis",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/TMS_4N_1.png"],
      },
    ],
  },
];

export const microkeratome = [
  {
    id: 1,
    logo: "/images/refractive/logo-moria-white.png",
    model: "Moria Evo 3e console with SBK set",
    //   name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Fastest flap creation (4 sec)",
      "Predictable thin and Planer flap",
      "Excellent stromal surface smoothness",
      "Excellent safety profile",
      "Very fast visual recovery",
      "Excellent quality of vision",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/refractive/e779c2270408b19d962770cc05614a25b835b607.png"],
    video: [
      {
        imggallery: ["/images/refractive/Scroll Group 1.png"],
        videoSrc: "/images/refractive/Screenshot (55).png",
        videoTitle: "SBK Surgical Process",
        videoUrl: "https://youtu.be/tADaSl_HtqA",
      },
    ],
    images: [
      "/images/refractive/Screenshot (55).png",
      "/images/refractive/Screenshot (55).png",
      "/images/refractive/Screenshot (55).png",
      "/images/refractive/Screenshot (55).png",
      "/images/refractive/Screenshot (55).png",
    ],
  },
];

export const asoct = [
  {
    id: 1,
    model: "MS-39",
    data: [
      {
        id: 1,
        logo: "/images/cataract/CSO.png",
        model: "MS-39",
        // name: "Treatment Table",
        main_points: [
          "HR corneal sections images on a diameter of 16 mm",
          "Epithelial & stromal map",
          "Early Keratoconus screening with ABCD grading",
          "Corneal aberrometer",
          "Crystalline biometery",
          "Pupillography",
          "IOL calculation with Ray tracing",
          "Intrastromal ring",
          "Advanced analysis of Tear film",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/MS-39-9.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "CASIA2",
    data: [
      {
        id: 1,
        logo: "/images/cataract/CSO.png",
        model: "CASIA2",
        // name: "Treatment Table",
        main_points: [
          "Application for cataract/glaucoma/ cornea surgery",
          "Epithelium mapping",
          "Glaucoma angle analysis",
          "Advanced imaging at high resolution and deep scanning depth (13 mm)",
          "Lens shape analysis and trend analysis",
          "Phakic IOL simulation",
          "Pre- and Post-Op ICL function",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/CASIA2-set-(high-resolution).png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const keratoplasty = [
  {
    id: 1,
    model: "VACUUM TREPHINE & PUNCH",
    data: [
      {
        id: 1,
        logo: "/images/cornea/MORIA.png",
        model: "VACUUM TREPHINE & PUNCH",
        // name: "Treatment Table",
        main_points: [
          "A precise and centred positioning",
          "A vertical cut",
          "Ergonomic design",
          "Adjustable & Non-adjustable depth",
          "Controlled and safe",
          "2-piece design with an Ultra-sharp pre-loaded blade",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: [
          "/images/refractive/Vacuum-punch.png",
          "/images/refractive/Adjustable-vacuum-Trephine.png",
        ],
        video: [
          {
            imggallery: [
              "/images/refractive/Vacuum-punch.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Vacuum-punch.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "DSAEK + ALTK SYSTEM",
    data: [
      {
        id: 1,
        logo: "/images/cornea/MORIA.png",
        model: "DSAEK + ALTK SYSTEM",
        // name: "Treatment Table",
        main_points: [
          "Linear system",
          "High degree of accuracy & reproducibility",
          "Range of pre-calibrated single use heads",
          "For Ultra-Thin DSAEK , as well as conventional DSAEK",
          "Very fast visual recovery",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/image010.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            video: [
              {
                src: "https://youtu.be/AKa2ABRRgPs",
                title: "CBM ALTK+DSAEK System",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
              {
                src: "https://youtu.be/AKa2ABRRgPs",
                title: "UT DSAEK Single Pass Short",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const dryice = [
  {
    id: 1,
    model: "IDRA",
    data: [
      {
        id: 1,
        logo: "/images/refractive/SBM.png",
        model: "IDRA",
        // name: "Treatment Table",
        main_points: [
          "Quick-Accurate- Non-invasive",
          "15 sec of acquisition in fully automatic mode",
          "Integrated and easy to use",
          "Evaluation of all tear film layers",
          // "Optimal working conditions for the surgeon",
          // "Robust steel chassis with four double castors",
          // "IOL calculation with Ray tracing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: [
          "/images/refractive/_DSC8311-B-copia.png",
          // "/images/refractive/Adjustable-vacuum-Trephine.png",
        ],
        video: [
          {
            imggallery: [
              "/images/refractive/_DSC8311-B-copia.png",
              "/images/refractive/_DSC8311-B-copia.png",
              "/images/refractive/_DSC8311-B-copia.png",
              "/images/refractive/_DSC8311-B-copia.png",
            ],

            video: [
              {
                src: "https://youtu.be/AKa2ABRRgPs",
                title: "CBM ALTK+DSAEK System",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
              {
                src: "https://youtu.be/AKa2ABRRgPs",
                title: "UT DSAEK Single Pass Short",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "ACTIVA",
    data: [
      {
        id: 1,
        logo: "/images/refractive/SBM.png",
        model: "ACTIVA",
        // name: "Treatment Table",
        main_points: [
          "Safe and effective",
          "Long lasting relief",
          "Innovative eye mask",
          "Fast and painless",
          "Easily sanitized and no gel required",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cornea/active.png"],
        video: [
          {
            imggallery: [
             "/images/cornea/active.png",
              "/images/refractive/Activa_2.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },

  {
    id: 3,
    model: "TEARCHECK",
    data: [
      {
        id: 1,
        logo: "/images/cornea/ESW.png",
        model: "TEARCHECK",
        // name: "Treatment Table",
        main_points: [
          "Simple and Fast",
          "9 exams for smart analysis",
          "Innovative eye mask",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/111A1969.png"],
        video: [
          {
            imggallery: [
              "/images/refractive/111A1969.png",
              "/images/refractive/111A1969.png",
              "/images/refractive/111A1969.png",
              "/images/refractive/111A1969.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
            ],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },

  {
    id: 4,
    model: "E-EYE",
    data: [
      {
        id: 1,
        logo: "/images/cornea/ESW.png",
        model: "E-EYE",
        // name: "Treatment Table",
        main_points: [
          "IRPL technology",
          "Simple and Fast",
          "Instant improvement",
          "Long lasting",
          "Quantified efficiency",
          "Safe & tailor-made",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/E-Eye-profile_web.png"],
        video: [
          {
            imggallery: ["/images/refractive/E-Eye-profile_web.png"],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
            ],
            // videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const specular = [
  {
    id: 1,
    logo: "/images/cornea/TOMEY.png",
    model: "EM-4000",
    // name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Fully automatic with touch screen",
      "13 measurement area",
      "L-count, Trace, Core method, Dark Area analysis",
      "Counts more than 300 cells",
      "Integrated Non-contact pachy",
    ],
    additionalInfo: [
      // {
      //   id: 1,
      //   title: "Publications",
      // },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/refractive/_0-3-EM_4000_3_a.png"],
    video: [
      {
        imggallery: [
          "/images/refractive/_0-3-EM_4000_3_a.png",
          "/images/refractive/EM_4000_2.png",
          "/images/refractive/EM_4000_3.png",
        ],
        // videoSrc: "/images/cataract/overview.png",
        // videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];
export const pachymeter = [
  {
    id: 1,
    model: "Pachette 4",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "Pachette 4",
        name: "Gold standard in in desktop Pachymetry",
        main_points: [
          "Easy, Fast and Accurate",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/Pachette 4.png"],
        imggallery: ["/images/rectina/Pachette 4.png"],
        video: [
          "/images/rectina/Mask Group 38.png",
          "/images/rectina/Turn-on-Power-Pachette-4.png",

          {
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Pachette 2",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "Pachette 2",
        name: "Handheld Pachymetry",
        main_points: [
          "Easy, Fast and Accurate",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/Pachmate-2.png"],
        video: [
          {
            imggallery: ["/images/rectina/Pachmate-2.png"],
          },
          {
            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/overview.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/images/cataract/video_2.png",
                   videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
            ],
          },
        ],
      },
    ],
  },
];
