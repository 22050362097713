import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast"; // Import Toaster
import CategoryTopSection from "../../components/category/CategoryTopSection";
import SectionHeading from "../../components/common/SectionHeading";
import Button from "../../components/common/Button";
import Aos from "aos";

const Contact = () => {
  const contactInfo = [
    {
      id: 1,
      icon: "/images/contact/mail.png",
      title: "Email",
      text: <span className="underline">info@myhealthskape.com</span>,
      link: "mailto:info@myhealthskape.com", // Email link
    },
    {
      id: 2,
      icon: "/images/contact/address.png",
      title: "Address",
      text: "My Healthskape Medicals Pvt Ltd 4/425A, Sharma Industrial Estate, Walbhat Road, Goregaon (East), Mumbai - 400 063 India.",
      link: "https://www.google.com/maps?q=My+Healthskape+Medicals+Pvt+Ltd", // Google Maps link
    },
    {
      id: 3,
      icon: "/images/contact/phone.png",
      title: "Contact number",
      text: "+91-22-26862626 / 26862828 / 26862929",
      link: "tel:+912226862626", // Telephone link
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Restrict phone input to numbers only
    if (name === "phone" && !/^\d*$/.test(value)) {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!formData.name) newErrors.name = "Name is required.";

    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Phone validation
    if (!formData.phone) newErrors.phone = "Phone number is required.";

    // Subject validation
    if (!formData.subject) newErrors.subject = "Subject is required.";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Resetting form and errors on successful submission
    setFormData({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    setErrors({});

    // Show success notification
    // Show success toast at the bottom
    toast.success("Form submitted successfully!", {
      position: "bottom-center",
    });
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <section>
      <CategoryTopSection
        breadcrumb={"Home | Contact Us"}
        centerHeading={"Contact Us"}
      />

      <div
        className="grid md:grid-cols-2 grid-cols-1 md:px-[7.29vw] md:py-[6.25vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {/* left side */}
        <div className="flex flex-col items-start gap-y-80px-vw justify-start">
          {contactInfo.map((item) => (
            <a
              key={item.id}
              href={item.link}
              className="flex items-start gap-x-20px-vw"
            >
              <div className="md:w-60px-vw aspect-[1/1]">
                <img
                  src={item.icon}
                  alt="icon"
                  className="w-full h-full object-cover"
                />
              </div>
              <div
                className={`flex flex-col text-[#2B2A29] font-raleway items-start justify-start ${
                  item.title === "Address" ? "md:max-w-[60%]" : ""
                }`}
              >
                <div className="md:text-24px-vw font-semibold">
                  {item.title}
                </div>
                <div className="md:text-22px-vw font-regular w-full">
                  {item.text}
                </div>
              </div>
            </a>
          ))}
          <div className="w-full h-full">
            <a
              href="https://www.google.com/maps?q=My+Healthskape+Medicals+Pvt+Ltd"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <img
                src="/images/contact/map.png"
                alt="map"
                className="w-full h-full object-cover"
              />
            </a>
          </div>
        </div>
        {/* right side */}
        <div className="w-full md:pl-[6.46vw]">
          <div className="justify-self-start md:mb-60px-vw">
            <SectionHeading title={"GET IN TOUCH"} />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-y-75px-vw text-[#2B2A29] items-start justify-start">
              <div className="w-full">
                <label
                  htmlFor="name"
                  className="font-poppins text-[#2B2A29] font-medium text-28px-vw"
                >
                  Name*
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full px-0 pt-20px-vw pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Name"
                />
                {errors.name && <p className="text-red-500">{errors.name}</p>}
              </div>

              <div className="w-full">
                <label
                  htmlFor="email"
                  className="font-poppins font-medium text-28px-vw"
                >
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full px-0 pt-20px-vw pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Email ID"
                />
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>

              <div className="w-full">
                <label
                  htmlFor="phone"
                  className="font-poppins font-medium text-28px-vw"
                >
                  Phone Number*
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="block w-full px-0 pt-20px-vw pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Phone Number"
                />
                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
              </div>

              <div className="w-full">
                <label
                  htmlFor="subject"
                  className="font-poppins font-medium text-28px-vw"
                >
                  Subject*
                </label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="block w-full px-0 pt-20px-vw pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Subject"
                />
                {errors.subject && (
                  <p className="text-red-500">{errors.subject}</p>
                )}
              </div>

              <div className="w-full">
                <label
                  htmlFor="message"
                  className="font-poppins font-medium text-28px-vw"
                >
                  Message
                </label>
                <input
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="block w-full px-0 pt-20px-vw pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Message"
                />
              </div>

              <div className="">
                <Button title={"Submit"} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
