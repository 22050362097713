import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import Slider from "react-slick";
import Aos from "aos";
import { oct } from "../../../Data/RectinaGlaucoma";
import Marquee from "react-marquee-slider";

import BottomSlider from "../../slider/BottomSlider";

const Oct = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <BsArrowRight className="text-white text-2xl" />,
    prevArrow: <BsArrowLeft className="text-white text-2xl" />,
  };
  const activeCategory = oct[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | OCT"}
        centerHeading={"OCT"}
      />

      <section
        className="w-full  md:py-96px-vw "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="flex items-center  justify-center">
          <div className=" rounded-[4vw]  backdrop-blur-[30px] bg-white bg-opacity-60 md:flex md:flex-row flex-col items-start md:items-center justify-center space-x-60px-vw z-10  gap-y-40px-vh md:gap-y-0 p-24px-vw">
            {oct.map((item, index) => (
              <div
                key={item.id}
                className={`relative cursor-pointer ${
                  activeIndex === index
                    ? "opacity-100 font-bold   md:rounded-[3vw] rounded-[8vw] p-30px-vw py-12px-vh"
                    : "opacity-40 font-medium"
                }`}
                onClick={() => setActiveIndex(index)}
              >
                {activeIndex === index && (
                  <div className="absolute green-shadow z-0 border "></div>
                )}
                {/* Category name */}
                <div
                  className={`relative ${
                    activeIndex === index
                      ? "glass-bg w-[250px] h-[50px]   "
                      : ""
                  } flex items-center justify-center `}
                >
                  <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.3vw] text-18px-mvw ">
                    {item.model}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full ">
            <div className="grid md:grid-cols-2 md:px-[7.29vw] gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-10/12 h-auto object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80 md:max-w-[90%]">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{item}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-end justify-end ">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:min-w-[38vw]  object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            <h1 className="font-raleway text-center font-bold md:text-32px-vw text-[#2B2A29]">
              PRODUCT KEY FEATURES
            </h1>
            <div className="w-full h-full md:px-[7.29vw] md:my-60px-vw grid md:grid-cols-3 gap-20px-vw">
              {/* First Grid (First 4 objects) */}
              <div className="grid md:grid-cols-1 md:pr-[4vw] gap-20px-vw">
                {item.keyFeatureObject.key_feature.slice(0, 4).map((data) => (
                  <div
                    key={data.id}
                    className="flex flex-col font-poppins text-[#2B2A29] pb-60px-vw items-start justify-start"
                  >
                    <div className="flex items-center gap-x-20px-vw">
                      <div className="bg-[#519A7F] w-60px-vw h-60px-vw p-8px-vw rounded-full">
                        <img src={data.img} alt="img" />
                      </div>
                      <div className="text-24px-vw font-semibold">
                        {data.title}
                      </div>
                    </div>
                    <div className="font-regular text-20px-vw opacity-80 md:pt-24px-vw leading-relaxed">
                      {data.desc}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-center">
                <img src={item.keyFeatureObject.commonImg} alt="" />
              </div>

              {/* Second Grid (Next 4 objects) */}
              <div className="grid md:grid-cols-1  md:pl-[4vw] gap-20px-vw">
                {item.keyFeatureObject.key_feature.slice(4, 8).map((data) => (
                  <div
                    key={data.id}
                    className="flex flex-col font-poppins text-[#2B2A29] pb-40px-vw items-start justify-start"
                  >
                    <div className="flex items-center  w-full gap-x-20px-vw">
                      <div className="bg-[#519A7F] w-60px-vw  h-60px-vw p-6px-vw rounded-full">
                        <img src={data.img} alt="img" />
                      </div>
                      <div className="text-24px-vw font-semibold">
                        {data.title}
                      </div>
                    </div>
                    <div className="font-regular text-20px-vw opacity-80 md:pt-20px-vw  leading-relaxed">
                      {data.desc}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full md:px-[7.29vw]  gap-x-16px-vw md:pt-[3vw]  flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }

            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[6.25vw]">
                  <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>

                  {/* Image Gallery Section */}
                  <div
                    className="w-full flex md:py-60px-vw items-center  justify-center gap-x-60px-vw"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                  >
                    {/* <Marquee
                      velocity={50}
                      minScale={0.8}
                      className="partner-marquee"
                      direction="left"
                      pauseOnHover={isPaused}
                    >
                      
                      {item.imggallery.map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`imgGallery-${index}`}
                          className="md:max-w-[24.23vw] md:px-20px-vw object-cover"
                        />
                      ))}
                    </Marquee> */}

                    <Marquee
                      velocity={50}
                      minScale={0.8}
                      className="partner-marquee"
                      pauseOnHover={isPaused}
                    >
                      {item.imggallery.map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`imgGallery-${index}`}
                          className="md:max-w-[24.23vw] md:max-h-[24.23vw] md:px-25px-vw object-cover"
                        />
                      ))}
                    </Marquee>
                  </div>

                  {/* Separator Line */}
                  <div className="absolute left-0 md:w-full md:mb-[7vw]  border-b-2 opacity-10 border-[#2B2A29] z-10"></div>

                  {/* Video and Image Navigation slider */}
                  <BottomSlider slides={item.videoData} />
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Oct;

// import React, { useState } from "react";
// import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

// const Slider = () => {
//   const slides = [
//     {
//       id: 1,
//       image: "/images/category/1.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 1: High-Resolution Retina Imaging",
//       description: "Experience the ultimate clarity and precision with high-resolution retina imaging tools.",
//     },
//     {
//       id: 2,
//       image: "/images/category/2.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 2: Advanced Eye Diagnostics",
//       description: "Explore cutting-edge diagnostic tools for comprehensive eye care.",
//     },
//     {
//       id: 3,
//       image: "/images/category/3.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 3: Next-Gen Vision Care",
//       description: "Revolutionize eye care with next-generation tools for improved vision solutions.",
//     },
//     {
//       id: 4,
//       image: "/images/category/4.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 4: Precise Retinal Analysis",
//       description: "Gain accurate and detailed retinal insights with advanced tools.",
//     },
//     {
//       id: 5,
//       image: "/images/category/5.png",
//       video: "https://www.youtube.com/embed/CuPHd0V9cgI",
//       title: "Slide 5: Innovative Eye Tools",
//       description: "Discover innovative eye tools designed to transform the future of eye care.",
//     },
//   ];

//   const [activeIndex, setActiveIndex] = useState(0); // Track active slide
//   const [isVideoPlaying, setIsVideoPlaying] = useState(false); // Track video playback

//   const handlePrev = () => {
//     setActiveIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
//     setIsVideoPlaying(false); // Stop video when navigating
//   };

//   const handleNext = () => {
//     setActiveIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
//     setIsVideoPlaying(false); // Stop video when navigating
//   };

//   const handlePlayVideo = () => {
//     setIsVideoPlaying(true);
//   };

//   return (
//     <div className="md:mt-[7vw] flex flex-col items-start relative">
//       <div className="flex items-center md:gap-x-30px-vw">
//         {/* Left Arrow with Gallery Image */}
//         <div className="relative">
//           <img
//             src={slides[(activeIndex - 1 + slides.length) % slides.length].image}
//             alt="Previous"
//             className="md:p-40px-vw w-full h-full object-cover"
//           />
//           <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
//           <div className="absolute inset-0 flex w-full h-full items-center justify-center">
//             <div
//               className="border border-solid rounded-full border-white p-30px-vw cursor-pointer"
//               onClick={handlePrev}
//             >
//               <BsArrowLeft className="md:text-[2vw] text-white" />
//             </div>
//           </div>
//         </div>

//         {/* Active/Center Slide */}
//         <div className="flex items-start relative flex-col justify-start">
//           {isVideoPlaying ? (
//             <iframe
//               src={slides[activeIndex].video}
//               title="Video Player"
//               className="w-full h-full md:max-w-[42.24vw]"
//               frameBorder="0"
//               allow="autoplay; encrypted-media"
//               allowFullScreen
//             ></iframe>
//           ) : (
//             <div className="relative cursor-pointer" onClick={handlePlayVideo}>
//               <img
//                 src={slides[activeIndex].image}
//                 alt="Active"
//                 className="md:max-w-[42.24vw] block"
//               />
//               <div className="absolute z-10 inset-0 flex items-center justify-center">
//                 {slides[activeIndex].video && (
//                   <img
//                     src="/icon/playIcon.png"
//                     alt="play icon"
//                     className="md:w-73px-vw"
//                   />
//                 )}
//               </div>
//             </div>
//           )}
//           <div className="md:pt-20px-vw text-[#2B2A29] font-poppins font-medium text-32px-vw">
//             {slides[activeIndex].title}
//           </div>
//           <div className="font-poppins md:pt-20px-vw font-regular text-20px-vw text-[#2B2A29] opacity-80">
//             {slides[activeIndex].description}
//           </div>
//         </div>

//         {/* Right Arrow with Gallery Image */}
//         <div className="relative">
//           <img
//             src={slides[(activeIndex + 1) % slides.length].image}
//             alt="Next"
//             className="md:p-40px-vw w-full h-full object-cover"
//           />
//           <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
//           <div className="absolute inset-0 flex w-full h-full items-center justify-center">
//             <div
//               className="border border-solid rounded-full border-white p-30px-vw cursor-pointer"
//               onClick={handleNext}
//             >
//               <BsArrowRight className="md:text-[2vw] text-white" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Slider;
