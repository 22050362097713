import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import AboutTopSection from "../../components/aboutus/AboutTopSection";
import { data, sustainabilityData } from "../../Data/DummyData";
import OurHistory from "../../components/aboutus/OurHistory";
import Vision from "../../components/aboutus/Vision";
import Achievements from "../../components/aboutus/Achievements";
import OurTeam from "../../components/aboutus/OurTeam";
import OurPartners from "../../components/home/OurPartners";
import SectionHeading from "../../components/common/SectionHeading";
import ParaText from "../../components/common/ParaText";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Aos from "aos";
import Marquee from "react-marquee-slider";

const AboutUs = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [velocity, setVelocity] = useState(40);
  const PartnersImage = [
   
    { id: 2, imgsrc: "/images/alliance/2.png" },
    { id: 3, imgsrc: "/images/alliance/3.png" },
    { id: 4, imgsrc: "/images/alliance/4.png" },
    { id: 5, imgsrc: "/images/alliance/5.png" },
    { id: 6, imgsrc: "/images/alliance/6.png" }, 
    { id: 1, imgsrc: "/images/alliance/7.png" },
  ]; 
  const Partners = [
   
    { id: 2, imgsrc: "/images/alliance/8.png" },
    { id: 3, imgsrc: "/images/alliance/9.png" },
    { id: 4, imgsrc: "/images/alliance/10.png" },
    { id: 5, imgsrc: "/images/alliance/11.png" },
    { id: 6, imgsrc: "/images/alliance/1.png" }, 
    { id: 1, imgsrc: "/images/alliance/13.png" },
  ]; 
  const Partnerslogo = [
  
    { id: 2, imgsrc: "/images/alliance/14.png" },
    { id: 3, imgsrc: "/images/alliance/15.png" }, 
    { id: 4, imgsrc: "/images/alliance/10.png" },
    { id: 1, imgsrc: "/images/alliance/1.png" },
   
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in-out",
      duration: 800,
      once: false,
      offset: 100,
    });
  }, []);

  useEffect(() => {
    // Adjust velocity based on window width
    const handleResize = () => {
      if (window.innerWidth <= 375) {
        // Mobile breakpoint
        setVelocity(20); // Decrease speed for mobile
      } else {
        setVelocity(40); // Default speed for larger screens
      }
    };

    handleResize(); // Set initial velocity
    window.addEventListener("resize", handleResize); // Update on resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener
    };
  }, []);

  return (
    <main className="w-full ">
      <CategoryTopSection
        breadcrumb={"Home | About Us"}
        centerHeading={"About Us"}
      />

      <section className="w-full md:px-[5vw] md:py-[6.25vw]">
        <AboutTopSection heading={"ABOUT US"} desc={data} />
        <OurHistory />
        <Vision />
        <Achievements />
        <OurTeam />
        <div className="md:py-[6.25vw]">
          {/* <AboutTopSection
            heading={"SUSTAINABILITY"}
            desc={sustainabilityData}
            CustomCss={"order-2 "}
          />   */}

          <div
            className={` w-full md:px-[7.29vw] grid grid-cols-2 gap-x-80px-vw`}
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="150"
          >
            <div className={`w-full  md:py-40px-vw  ${"order-2"}`}>
              <SectionHeading title={"SUSTAINABILITY"} />
              <ParaText
                paratext={sustainabilityData}
                CustomCss={"md:mt-40px-vw"}
              />

              <div className="w-full md:mt-[1vw] md:flex md:items-start md:justify-start">
                <div className="rounded-[4vw] border-[1px] border-[#2B2A29] md:flex items-center justify-center px-24px-vw py-18px-vh hidden  cursor-pointer mt-30px-vh">
                  <div className="inline-flex text-center gap-x-10px-vw">
                    <button
                      className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-40px-vw opacity-[0.8]"
                      // onClick={handlePrev}
                    >
                      <BsArrowLeft />
                    </button>
                    <button
                      className="text-[#2B2A29] font-poppins md:text-[1.24vw] text-40px-vw opacity-[0.8]"
                      // onClick={handleNext}
                    >
                      <BsArrowRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-full rounded-[1.5vw]">
              <img src="/images/about/plant.png" alt="plant" />
            </div>
          </div>
        </div>
        {/* <OurPartners
          heading={"ALLIANCE PARTNERS"}
          CustomCss={"md:pl-0 md:pr-0"}
        /> */}
        <section
          className={`w-full relative grid grid-cols-1 place-items-center pl-88px-vw pr-62px-vw md:pl-0 md:pr-0`}
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          <div className="text-center">
            <SectionHeading
              title={"ALLIANCE PARTNERS"}
              customCss={"uppercase my-20px-mvw md:my-80px-vh"}
            />
          </div>
          <div
            className="relative overflow-hidden md:py-65px-vh flex items-center  py-35px-mvw bg-opacity-15 z-10 rounded-[4vw] md:rounded-[1.04vw] "
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
          
            {PartnersImage.map((item) => (
              <div key={item.id} className="flex-shrink-0 mx-4">
                <img
                  src={item.imgsrc}
                  alt={`Partner ${item.id}`}
                  className="md:mr-[3vw] mr-32px-mvw md:h-[6vh] h-[3vh]"
                />
              </div>
            ))}              
          </div>   
          <div className="hidden md:block  absolute left-0 right-0 my-[5.5vw] w-full border-b-2 opacity-10 border-[#2B2A29]  z-10 mx-auto  px-4 md:px-0"></div>         
          <div
            className="relative overflow-hidden md:py-65px-vh flex items-start  py-35px-mvw bg-opacity-15 z-10 rounded-[4vw] md:rounded-[1.04vw] "
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
          
            {Partners.map((item) => (
              <div key={item.id} className="flex-shrink-0 mx-4">
                <img
                  src={item.imgsrc}
                  alt={`Partner ${item.id}`}
                  className="md:mr-[5vw] mr-32px-mvw md:h-[6vh] h-[3vh]"
                />
              </div>
            ))}         
            <div className="hidden md:block  absolute left-0 right-0 my-[5.5vw] w-full border-b-2 opacity-10 border-[#2B2A29]  z-10 mx-auto  px-4 md:px-0"></div>               
          </div>  
          <div
            className="relative overflow-hidden md:py-65px-vh flex items-start  py-35px-mvw bg-opacity-15 z-10 rounded-[4vw] md:rounded-[1.04vw] "
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
          
            {Partnerslogo.map((item) => (
              <div key={item.id} className="flex-shrink-0 mx-4">
                <img
                  src={item.imgsrc}
                  alt={`Partner ${item.id}`}
                  className="md:mr-[5vw] mr-32px-mvw md:h-[6vh] h-[3vh]"
                />
              </div>
            ))}     
            <div className="hidden md:block  absolute left-0 right-0 my-[5.5vw] w-full border-b-2 opacity-10 border-[#2B2A29]  z-10 mx-auto  px-4 md:px-0"></div>          
          </div> 
        </section> 
       
       
      </section>
    </main>
  );
};

export default AboutUs;
