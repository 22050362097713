import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import { slitLamp } from "../../../Data/PrimaryDiagnostics";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";

const SlitLamp = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Safely access active category
  const activeCategory = slitLamp[activeIndex] || {};

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Primary Diagnostics | Slit-Lamp"}
        centerHeading={"Slit-Lamp"}
      />

      <section
        className="w-full  md:py-96px-vw md:px-[7.29vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="flex items-center justify-center">
          <div className="rounded-[4vw] backdrop-blur-[30px] bg-white bg-opacity-60 md:flex md:flex-row flex-col items-start md:items-center justify-center space-x-60px-vw z-10 gap-y-40px-vh md:gap-y-0 p-24px-vw">
            {slitLamp.map((item, index) => (
              <div
                key={item.id}
                className={`relative cursor-pointer ${
                  activeIndex === index
                    ? "opacity-100 font-bold md:rounded-[3vw] rounded-[8vw] p-30px-vw py-12px-vh"
                    : "opacity-40 font-medium"
                }`}
                onClick={() => setActiveIndex(index)}
              >
                {activeIndex === index && (
                  <div className="absolute green-shadow z-0 border"></div>
                )}
                <div
                  className={`relative ${
                    activeIndex === index ? "glass-bg w-[250px] h-[50px]" : ""
                  } flex items-center justify-center`}
                >
                  <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.3vw] text-18px-mvw">
                    {item.model}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {activeCategory.data?.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 grid-cols-1 md:py-60px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="md:w-[9.58vw]">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((point, index) => (
                  <div
                    key={index}
                    className="w-full flex items-center gap-x-20px-vw"
                  >
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{point}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw">
                {item.img.map((img, index) => (
                  <div key={index}>
                    <img src={img} alt="" className="w-full h-full object-cover" />
                  </div>
                ))}
              </div>
            </div>

            {item.additionalInfo && (
              <div className="w-full gap-x-16px-vw md:pt-[3vw] md:px-[14vw] mx-auto flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            )}

            {item.video && (
              <div className="w-full flex flex-col items-center justify-center md:pt-[6.25vw]">
                <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                  IMAGE & VIDEO GALLERY
                </h1>
                <div className="md:mt-60px-vw flex flex-col items-start relative">
                  <iframe
                    className="md:max-w-[80vw] block"
                    width="700"
                    height="450"
                    src={item.video.videoSrc}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="md:pt-20px-vw text-[#2B2A29] font-poppins font-medium text-32px-vw">
                    Video Title
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </section>
    </main>
  );
};

export default SlitLamp;
