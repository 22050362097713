// MetaData.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MetaData = () => {
    const location = useLocation();

    useEffect(() => {
        const loadMetaData = async () => {
            try {
                const response = await fetch('/metaData/metadata.json');
                const data = await response.json();

                // Get the current path (e.g., "/about-us")
                const currentPath = location.pathname;

                // Find metadata for the current path
                const pageMeta = data.find((item) => 
                    new URL(item.Pages, window.location.origin).pathname === currentPath
                );

                if (pageMeta) {
                    // Set document title
                    document.title = pageMeta["Meta Title"];

                    // Set meta description
                    const descriptionTag = document.querySelector("meta[name='description']");
                    if (descriptionTag) {
                        descriptionTag.setAttribute("content", pageMeta["Meta Description"]);
                    } else {
                        const newDescriptionTag = document.createElement("meta");
                        newDescriptionTag.name = "description";
                        newDescriptionTag.content = pageMeta["Meta Description"];
                        document.head.appendChild(newDescriptionTag);
                    }

                    // Set canonical URL
                    const canonicalTag = document.querySelector("link[rel='canonical']");
                    if (canonicalTag) {
                        canonicalTag.setAttribute("href", pageMeta["Canonical Urls"]);
                    } else {
                        const newCanonicalTag = document.createElement("link");
                        newCanonicalTag.rel = "canonical";
                        newCanonicalTag.href = pageMeta["Canonical Urls"];
                        document.head.appendChild(newCanonicalTag);
                    }
                }
            } catch (error) {
                console.error("Error loading metadata:", error);
            }
        };

        loadMetaData();
    }, [location.pathname]); // Run effect whenever pathname changes

    return null;
};

export default MetaData;
