export const slitLamp = [
  {
    id: 1,
    model: "SL9900/SL9800",
    data: [
      {
        id: 1,
        logo: "/images/primary/cso.png",
        model: "SL9900/SL9800",
        name: "LED Slit Lamp",
        main_points: [
          "Ergonomic design",
          "High quality optics",
          "Precision mechanical parts",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/primary/SL9800.png", "/images/primary/SL9900.png"],
        video: "",
      },
    ],
  },
  {
    id: 2,
    model: "SL9900 ELITE HR",
    data: [
      {
        id: 1,
        logo: "/images/primary/CSO.png",
        model: "SL9900 ELITE HR",
        name: "LED Photo Slit Lamp",
        main_points: [
          "Ergonomic design",
          "High quality optics",
          "Precision mechanical parts",
          "Phoenix software Patient data filing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/primary/TL-7000.png"],
        video: {
         videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
          image:"/images/primary/TL-7000.png"
        },
      },
    ],
  },
];

export const lensmeter = [
  {
    id: 1,
    model: "TL-7000",
    data: [
      {
        id: 1,
        logo: "/images/primary/tomey.png",
        model: "TL-7000",
        name: "Auto Lensmeter",
        main_points: [
          "Hartmann sensor wavefront technology",
          "UV/Blue light measurement",
          "Lens mark recognition support",
          "Simple power mapping",
          "LAN & RS-232C connection",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/primary/TL-7000.png"],
        video: [
          {
            videoSrc: "/images/primary/video_thumb.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Product Description",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "TL-6000",
    data: [
      {
        id: 1,
        logo: "/images/primary/tomey.png",
        model: "TL-6000",
        name: "Auto Lensmeter",
        main_points: [
          "Hartmann sensor wavefront technology",
          "UV/Blue light measurement",
          "Lens mark recognition support",
          "LAN & RS-232C connection",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/primary/TL-7000.png"],
        video: "",
      },
    ],
  },
];
